<template>
  <div>
    <ion-datetime-button datetime="datetime" class="pickup-txt">
    </ion-datetime-button>
    <ion-modal :keep-contents-mounted="true" class="pickup-txt">
      <ion-datetime
        v-model="myDate"
        id="datetime"
        :show-default-buttons="true"
        presentation="time"
        done-text="Set"
        cancel-text="Cancel"
      ></ion-datetime>
    </ion-modal>
  </div>
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";

import {
  IonDatetime,
  IonDatetimeButton,
  IonPage,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  IonModal,
} from "@ionic/vue";

export default defineComponent({
  name: "OrderTime",
  components: {
    IonDatetime,
    IonDatetimeButton,
    IonPage,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonModal,
  },
  data() {
    return {
      myDate: new Date().toISOString(),
    };
  },
  created() {
    const newDate = moment(this.myDate).add(5, "minutes").toDate();
    this.myDate = moment(newDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  },
});
</script>
<style scoped>
.pickup-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #1b1b1b;
}
</style>
