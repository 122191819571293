<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="container">
        <img class="center-img" src="assets/placeholders/cafeheader1.png" />
        <router-link to="/home" replace>
          <img class="back-icon" src="assets/icon/arrowleft-circle.svg" />
        </router-link>
        <img class="heart-icon" src="assets/icon/heart.svg" />
      </div>
      <div class="cafeinfo">
        <div class="cafeinfo-div">
          <h1 class="h1-cafe">Cafe name</h1>
          <h2 class="h2-cafe">123 King William Rd, Adelaide</h2>
          <ion-icon
            src="assets/icon/time-outline.svg"
            class="banner-icon"
          ></ion-icon
          ><span class="cafeinfo-txt">11am - 9pm</span>
          <ion-icon
            src="assets/icon/dot-separate.svg"
            class="dotsep-icon"
          ></ion-icon>
          <ion-icon
            src="assets/icon/distance.svg"
            class="banner-icon"
          ></ion-icon
          ><span class="cafeinfo-txt">1.3km</span>
          <ion-icon
            src="assets/icon/dot-separate.svg"
            class="dotsep-icon"
          ></ion-icon>
          <ion-icon src="assets/icon/timer.svg" class="banner-icon"></ion-icon
          ><span class="cafeinfo-txt">Ready in 5 min</span>
        </div>
      </div>

      <div class="cafeinfo2">
        <div class="cafeinfo-div2">
          <span class="cafeinfo-heading">Rewards</span>
          <ion-row>
            <ion-col size="8" class="cafeinfo-p pd-leftnone"
              >Get a FREE coffee in 7 orders</ion-col
            >
            <ion-col class="cafeinfo-p-right">3/10</ion-col>
          </ion-row>
        </div>
      </div>

      <div class="cafeinfo2">
        <div class="cafeinfo-div2">
          <span class="cafeinfo-heading">Address</span>
          <span class="cafeinfo-p">123 King William Rd. Adelaide SA 5000</span>
          <iframe
            class="cafeinfo-maps"
            width="100%"
            height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13083.720463239684!2d138.58949763481033!3d-34.93328958388831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cf21e79a7f05%3A0x29dcc6dec5121a8b!2sCaf%C3%A9%20Troppo!5e0!3m2!1sen!2sph!4v1663640860528!5m2!1sen!2sph"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div class="cafeinfo2">
        <div class="cafeinfo-div2">
          <span class="cafeinfo-heading">Open</span>
          <ion-row>
            <ion-col class="cafeinfo-p pd-leftnone">Monday to Friday</ion-col>
            <ion-col class="cafeinfo-p-right">7am - 3pm</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="cafeinfo-p pd-leftnone">Saturday</ion-col>
            <ion-col class="cafeinfo-p-right">8am - 2pm</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="cafeinfo-p pd-leftnone">Sunday</ion-col>
            <ion-col class="cafeinfo-p-right">Closed</ion-col>
          </ion-row>
        </div>
      </div>

      <div class="cafeinfo2">
        <div class="cafeinfo-div2">
          <span class="cafeinfo-heading">Features</span>
          <p class="cafeinfo-p">
            Wi-Fi • Outdoof seating • Child friendly • Space for prams • Parking
            nearby • Wheelchair accessible • Close to public transport
          </p>
        </div>
      </div>

      <div class="cafeinfo2">
        <div class="cafeinfo-div2">
          <span class="cafeinfo-heading">About</span>
          <p class="cafeinfo-p">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      <div class="cafeinfo2">
        <div class="cafeinfo-div2">
          <span class="cafeinfo-heading">Social</span>
          <ion-row>
            <ion-col class="pd-leftnone"
              ><a href="http://google.com"
                ><img src="assets/icon/website.png" /></a
            ></ion-col>
            <ion-col
              ><a href="http://google.com"
                ><img src="assets/icon/facebook-grey.png" /></a
            ></ion-col>
            <ion-col
              ><a href="http://google.com"
                ><img src="assets/icon/instagram-grey.png" /></a
            ></ion-col>
            <ion-col
              ><a href="http://google.com"
                ><img src="assets/icon/snapchat-grey.png" /></a
            ></ion-col>
            <ion-col
              ><a href="http://google.com"
                ><img src="assets/icon/tripadvisor-grey.png" /></a
            ></ion-col>
            <ion-col
              ><a href="http://google.com"
                ><img src="assets/icon/yelp-grey.png" /></a
            ></ion-col>
          </ion-row>
        </div>
      </div>
    </ion-content>
    <!-- content end -->
  </ion-page>
</template>
<style scoped>
.container {
  position: relative;
}
.container img {
  display: block;
  height: 250px;
}
.center-img {
  margin-left: auto;
  margin-right: auto;
}
.container .back-icon {
  position: absolute;
  top: -60px;
  left: 20px;
}
.container .heart-icon {
  position: absolute;
  top: -60px;
  right: 20px;
}
.back-icon {
  height: 36px;
  width: 36px;
}
.heart-icon {
  height: 36px;
  width: 36px;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 11px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.cafeinfo-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
}
.mb-img {
  margin-top: 10%;
  margin-bottom: -5px;
}
.dblock {
  display: block;
}
.cafeinfo-div {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 5%;
  margin-top: -70px;
  z-index: 2;
  position: relative;
}
.cafeinfo-div2 {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 5%;
}
.cafeinfo {
  padding: 5%;
}
.cafeinfo2 {
  padding: 0% 5% 5% 5%;
}
.h1-cafe {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b1b;
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: left;
}
.h2-cafe {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #ff8000;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
}
.cafeinfo-heading {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
}
.cafeinfo-p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  color: #828282;
}
.cafeinfo-p-right {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  color: #828282;
  text-align: right;
}
.cafeinfo-maps {
  padding: 0%;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 185px;
}
.pd-leftnone {
  padding-left: 0px !important;
}
</style>
<script>
import { defineComponent } from "vue";
import { IonPage, IonContent, IonRow, IonCol, IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "CafeInfoPage",
  components: { IonPage, IonContent, IonRow, IonCol, IonIcon },
});
</script>
