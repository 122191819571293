<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="order-status">
        <div align="center" class="mb-img">
          <img src="assets/images/order-complete.png" />
        </div>
        <div class="orderstatus-div">
          <ion-row class="row-orderlist">
            <ion-col size="12">
              <span class="txtlight-sml">
                Order # {{ orderId.replace(/-/gi, "").toUpperCase() }}
              </span>
            </ion-col>
            <ion-col size="12">
              <span class="orderstatus-h1">We have received your order</span>
            </ion-col>
          </ion-row>
          <div>
            <ion-row class="row-orderlist">
              <!--<ion-col size="2">
                <span class="orderstatus-cancel">Cancel</span>
              </ion-col>-->
              <ion-col>
                <ion-icon
                  src="assets/icon/dot-separate.svg"
                  class="dotsep-icon"
                  color="primary"
                >
                </ion-icon>
                <ion-icon
                  src="assets/icon/notification-circle.svg"
                  class="notify-icon"
                >
                </ion-icon>
                <span class="orderstatus-notify">
                  Notifying your barista...
                </span>
              </ion-col>
            </ion-row>
          </div>
          <div>
            <ion-row class="row-orderlist">
              <ion-col>
                <span class="order-status-txtlight">Ready in</span>
              </ion-col>
              <ion-col>
                <span class="order-status-txtlight position-right">Time</span>
              </ion-col>
            </ion-row>
            <ion-row class="row-orderlist">
              <ion-col>
                <span class="order-status-bold">10 - 15mins</span>
              </ion-col>
              <ion-col>
                <span class="order-status-bold position-right">
                  15:30 - 15:45
                </span>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div class="orderstatus-div ion-margin-top">
          <ion-row
            class="row-orderlist"
            v-for="orders in cartOrders"
            :key="orders.id"
          >
            <ion-col size="2">
              <span class="order-status-bold">{{ orders.quantity }} x</span>
            </ion-col>
            <ion-col>
              <span class="order-status-bold">
                {{ orders.product.productName }}
              </span>
              <div v-for="variations in orders.variations" :key="variations.id">
                <span class="order-status-txtlight dblock">
                  {{ variations.name }}
                </span>
              </div>
            </ion-col>
            <ion-col>
              <span class="order-status-normal position-right">
                $ {{ orders.pricePerOrder.toFixed(2) }}
              </span>
            </ion-col>
          </ion-row>
          <ion-row class="row-orderlist hr-subtotal">
            <ion-col>
              <span class="order-status-normal">
                SubTotal ({{ totalCartQty }} item/s)
              </span>
            </ion-col>
            <ion-col>
              <span class="order-status-normal position-right">
                $ {{ subTotalCartPrice.toFixed(2) }}
              </span>
            </ion-col>
          </ion-row>
          <ion-row class="row-orderlist hr-subtotal">
            <ion-col>
              <span class="order-status-normal">Discount</span>
            </ion-col>
            <ion-col>
              <span class="order-status-normal position-right">
                - $ {{ discountPrice.toFixed(2) }}
              </span>
            </ion-col>
          </ion-row>
          <ion-row class="row-orderlist">
            <ion-col>
              <span class="order-status-normal">Service Fee</span>
            </ion-col>
            <ion-col>
              <span class="order-status-normal position-right">
                $ {{ serviceFee.toFixed(2) }}
              </span>
            </ion-col>
          </ion-row>
          <ion-row class="row-orderlist">
            <ion-col><span class="order-status-bold">Total</span> </ion-col>
            <ion-col>
              <span class="order-status-bold position-right">
                $ {{ totalCartPrice.toFixed(2) }}
              </span>
            </ion-col>
          </ion-row>
        </div>

        <iframe
          class="orderstatus-maps"
          width="100%"
          height="100%"
          :src="
            'https://www.google.com/maps?&q=' + cafeAddress + '&output=embed'
          "
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        >
        </iframe>
        <ion-row>
          <ion-col size="12">
            <span class="order-status-bold">
              {{ cafeName }}
            </span>
          </ion-col>
          <ion-col size="12">
            <span class="order-status-txtlight">
              {{ cafeAddress }}
            </span>
          </ion-col>
        </ion-row>
        <a href="/track">
          <ion-button expand="block" color="primary" class="orderstatus-btn">
            Track Your Order
          </ion-button>
        </a>
        <div align="center" class="ion-margin-top">
          <span class="support-link">
            <router-link to="/support"> Contact Support </router-link>
          </span>
        </div>
      </div>
    </ion-content>
    <!-- content end -->
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import {
  IonPage,
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  alertController,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "OrderStatusPage",
  components: { IonPage, IonContent, IonRow, IonCol, IonIcon, IonButton },
  data() {
    return {
      cartOrders: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      serviceFee: 0,
      totalCartPrice: 0,
      cafeId: "",
      cafeName: "",
      cafeAddress: "",
      orders: [],
      orderId: "",
      promptMessage: "",
      discountPrice: 0,
    };
  },
  methods: {
    async presentAlert(status) {
      const alert = await alertController.create({
        message: this.promptMessage,
      });

      await alert.present();
      await alert.onDidDismiss();
      if (status == 200 || status === 400) window.location.href = "/home";
      else return;
    },
    async getCartOrders(userId, cafeId, cartOrderIds) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/cart/get-cart-orders/?userId=" +
          userId +
          "&status=order_placed" +
          "&cafeId=" +
          cafeId +
          "&cartOrderIds=[" +
          cartOrderIds +
          "]"
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;
      }

      this.cafeId = this.cartOrders[0].cafe.cafeId;
      this.cafeName = this.cartOrders[0].cafe.shopName;
      this.cafeAddress = this.cartOrders[0].cafe.address;
    },
    async getOrders(userId, cafeId, cartOrderIds) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/orders/get-orders/?userId=" +
          userId +
          "&status=preparing" +
          "&cafeId=" +
          cafeId +
          "&cartOrderIds=[" +
          cartOrderIds +
          "]"
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available anymore.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];
      this.orderId = this.orders[0].uuid;
      this.discountPrice = this.orders[0].discount;
      this.serviceFee = result.data[0].serviceFee;
      this.totalCartPrice = result.data[0].total;
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cafeId = urlParams.get("cafeId") || "";
    const userId = localStorage.getItem("id") || null;

    const parsedParamsAddData = JSON.parse(
      localStorage.getItem("paramsAddData")
    );

    const parsedParamsUpdateData = JSON.parse(
      localStorage.getItem("paramsUpdateData")
    );

    const parsedRedeemRewardsData = JSON.parse(
      localStorage.getItem("redeemRewardsData")
    );

    if (
      localStorage.getItem("paramsAddData") &&
      localStorage.getItem("paramsUpdateData")
    ) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      await axios.post(
        BASE_URI + "/orders/create-order",
        parsedParamsAddData,
        axiosConfig
      );

      await axios.put(
        BASE_URI + "/cart/update-cart-orders",
        parsedParamsUpdateData,
        axiosConfig
      );

      if (parsedRedeemRewardsData.rewardsId != "") {
        await axios.post(
          BASE_URI + "/rewards/redeem-rewards",
          parsedRedeemRewardsData,
          axiosConfig
        );
      }
    } else {
      this.promptMessage = "This order's session has already expired.";
      this.presentAlert(400);
    }

    if (cafeId == "") window.location.href = "/cart";
    else {
      await this.getCartOrders(
        userId,
        cafeId,
        parsedParamsAddData.cartOrderIds
      );
      await this.getOrders(userId, cafeId, parsedParamsAddData.cartOrderIds);
    }

    localStorage.removeItem("paramsAddData");
    localStorage.removeItem("paramsUpdateData");
    localStorage.removeItem("redeemRewardsData");
  },
});
</script>
<style scoped>
.mb-img {
  margin-top: 10%;
  margin-bottom: -5px;
}
.dblock {
  display: block;
}
.orderstatus-div {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-top: 5%;
  padding-bottom: 5%;
}
.row-orderlist {
  padding-left: 7%;
  padding-right: 7%;
}
.orderstatus-h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
}
.orderstatus-cancel {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  color: #f04a3f;
}
.orderstatus-notify {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  color: #00bf60;
}
.notify-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 16px;
}
.order-status {
  padding: 5%;
}
.txtlight-sml {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
}
.order-status-txtlight {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
}
.order-status-bold {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
}
.order-status-normal {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
}
.position-right {
  position: absolute;
  right: 6px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 5px;
}
.hr-subtotal {
  border-top: 1px solid #f3f3f3;
  margin-top: 20px;
  padding-top: 20px;
}
.orderstatus-maps {
  padding: 0%;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 185px;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  margin-top: 16px;
  height: 52px;
  text-transform: none;
  --box-shadow: none;
}
.orderstatus-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}
.support-link a {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #ff8000;
  text-decoration: none;
}
</style>
