<template>
  <div v-if="cafeInfo">
    <div class="container">
      <img class="center-img fit-img" :src="cafeInfo.shopImgUrl" />
      <router-link to="/home" replace>
        <img class="back-icon" src="assets/icon/arrowleft-circle.svg" />
      </router-link>
      <a href="/cart"
        ><img class="cart-icon" src="assets/icon/cart-button.svg"
      /></a>
      <img
        class="heart-icon"
        v-if="!isFavorite"
        @click="markAsFavorite"
        :disabled="isProcessing"
        src="assets/icon/heart.svg"
      />
      <img
        class="heart-icon"
        v-if="isFavorite"
        @click="removeFromFavorites"
        :disabled="isProcessing"
        src="assets/icon/heart-red.svg"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { defineComponent } from "vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "cafeItemHeader",
  components: {},
  props: {
    cafeInfo: Object,
  },
  data() {
    return {
      cafeId: "",
      userId: "",
      isFavorite: false,
      isProcessing: false,
    };
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.cafeId = urlParams.get("id") || "";
    this.userId = localStorage.getItem("id") || null;

    // check if the coffee shop is already marked as a favorite
    let paramsData = {
      userId: this.userId,
      cafeId: this.cafeId,
    };

    let axiosConfig = {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      params: paramsData,
    };

    this.isFavorite = false;
    await axios
      .get(BASE_URI + "/favorites/get-favorites", axiosConfig)
      .then((response) => {
        if (parseInt(response.data.total_count) > 0) {
          this.isFavorite = true;
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  methods: {
    async markAsFavorite() {
      this.isProcessing = true;
      let paramsData = {
        userId: this.userId,
        cafeId: this.cafeId,
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      await axios
        .post(BASE_URI + "/favorites/create-favorites", paramsData, axiosConfig)
        .finally(() => {
          this.isProcessing = false;
        });

      this.isFavorite = true;
    },
    async removeFromFavorites() {
      this.isProcessing = true;
      let paramsData = {
        userId: this.userId,
        cafeId: this.cafeId,
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        params: paramsData,
      };

      await axios
        .delete(BASE_URI + "/favorites/delete-favorites", axiosConfig)
        .finally(() => {
          this.isProcessing = false;
        });

      this.isFavorite = false;
    },
  },
});
</script>
<style scoped>
.container {
  position: relative;
  overflow: hidden;
}
div .fit-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.container img {
  display: block;
  height: 200px;
}
.center-img {
  margin-left: auto;
  margin-right: auto;
}
.container .back-icon {
  position: absolute;
  top: -60px;
  left: 20px;
}
.container .heart-icon {
  position: absolute;
  top: -60px;
  right: 20px;
}
.container .cart-icon {
  position: absolute;
  top: -60px;
  right: 80px;
}
.back-icon {
  height: 36px;
  width: 36px;
}
.heart-icon {
  height: 36px;
  width: 36px;
}
.cart-icon {
  height: 36px;
  width: 36px;
}
</style>
