<template>
  <ion-page>
    <ion-content>
      <cafeItemHeader :cafeInfo="cafeInfo" />
      <div class="cafeinfo-container-sticky">
        <h1 class="h1-cafe">{{ cafeInfo.shopName }}</h1>
      </div>
      <div class="cafeinfo-container">
        <h2 class="h2-cafe">{{ cafeInfo.address }}</h2>
        <ion-icon
          src="assets/icon/time-outline.svg"
          class="banner-icon"
        ></ion-icon
        ><span class="cafeinfo-txt">11am - 9pm</span>
        <ion-icon
          src="assets/icon/dot-separate.svg"
          class="dotsep-icon"
        ></ion-icon>
        <ion-icon src="assets/icon/distance.svg" class="banner-icon"></ion-icon
        ><span class="cafeinfo-txt">1.3km</span>
        <ion-icon
          src="assets/icon/dot-separate.svg"
          class="dotsep-icon"
        ></ion-icon>
        <ion-icon src="assets/icon/timer.svg" class="banner-icon"></ion-icon
        ><span class="cafeinfo-txt">Ready in 5 min</span>
      </div>
      <!--content start -->
      <cafeItemDetailsDrawer
        :product="product"
        :variations="variations"
        :addons="addons"
        :productOriginalPrice="productOriginalPrice"
        :productId="productId"
        :cartOrders="cartOrders"
        :active="active.product_drawer"
        v-on:close-product-drawer="closeProductDrawer()"
      />
      <!--<ion-row class="heading-row"
        ><h1 class="heading-category">Most Popular</h1></ion-row
      >
      <div v-if="Object.keys(mostPopularCafeProducts).length !== 0">
        <cafeItemCard
          v-for="product in mostPopularCafeProducts"
          :key="product.id"
          :product="product"
          v-on:view-product="viewProduct($event)"
        />
      </div>
      <div style="padding: 1em; font-family: 'Gilroy'; font-size: 14px" v-else>
        No product/s available for this cafe.
      </div>-->

      <ion-row class="heading-row-list">
        <h1 class="heading-category">Hot</h1>
      </ion-row>
      <div v-if="Object.keys(hotCafeProducts).length !== 0">
        <cafeItemListCard
          v-for="product in hotCafeProducts"
          :key="product.id"
          :product="product"
          v-on:view-product="viewProduct($event)"
        />
      </div>
      <div style="padding: 1em; font-family: 'Gilroy'; font-size: 14px" v-else>
        No product/s available for this cafe.
      </div>

      <ion-row class="heading-row-list">
        <h1 class="heading-category">Cold</h1>
      </ion-row>
      <div v-if="Object.keys(coldCafeProducts).length !== 0">
        <cafeItemListCard
          v-for="product in coldCafeProducts"
          :key="product.id"
          :product="product"
          v-on:view-product="viewProduct($event)"
        />
      </div>
      <div style="padding: 1em; font-family: 'Gilroy'; font-size: 14px" v-else>
        No product/s available for this cafe.
      </div>
      <router-link to="/cart">
        <ion-button expand="block" color="primary">
          <span class="cartquantity-btn">{{ totalCartQty }}</span>
          <span class="carttext-btn">View Cart</span>
          <span class="cartprice-btn">
            $ {{ subTotalCartPrice.toFixed(2) }}
          </span>
        </ion-button>
      </router-link>
      <!-- <ion-row class="heading-row-list"
        ><h1 class="heading-category">Tea</h1></ion-row
      >
      <cafeItemListCard
        v-for="product in cafeitem"
        :key="product.id"
        :product="product"
        v-on:view-product="viewProduct($event)"
      />

      <ion-row class="heading-row-list"
        ><h1 class="heading-category">Extras</h1></ion-row
      >
      <cafeItemListCard
        v-for="product in cafeitem"
        :key="product.id"
        :product="product"
        v-on:view-product="viewProduct($event)"
      /> -->
    </ion-content>
    <TheFooter name="TheFooter" />
    <!-- content end -->
  </ion-page>
</template>
<style scoped>
.heading-row {
  background-color: #f9f9f9;
  margin-top: 20px;
  padding-left: 15px;
}
.heading-row-list {
  background-color: #f9f9f9;
  margin-top: 20px;
  padding-left: 15px;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
}
.heading-category {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #1b1b1b;
  margin-bottom: 15px;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: 130px;
  height: 52px;
  text-transform: none;
  --box-shadow: none;
}
ion-button:active {
  --color: #0057ff;
}
.carttext-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.cartprice-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  right: 16px;
}
.cartquantity-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  position: absolute;
  left: 16px;
  background-color: #ff5500;
  padding: 10px;
  border-radius: 4px;
}
.cafeinfo-container-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
  padding: 5px 20px 5px 20px;
}
.cafeinfo-container {
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid #f3f3f3;
}
.h1-cafe {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b1b;
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: left;
}
.h2-cafe {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #ff8000;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 11px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.cafeinfo-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
}
</style>
<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import axios from "axios";
import cafeitem from "@/data/cafeitem.js";
import cafeItemCard from "@/components/product/cafeItemCard.vue";
import cafeItemListCard from "@/components/product/cafeItemListCard.vue";
import cafeItemDetailsDrawer from "@/components/product/cafeItemDetailsDrawer.vue";
import cafeItemHeader from "@/components/product/cafeItemHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "CafeMenu",
  components: {
    IonPage,
    IonContent,
    TheFooter,
    cafeItemCard,
    cafeItemDetailsDrawer,
    cafeItemHeader,
    cafeItemListCard,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      cafeitem: cafeitem,
      productVariations: {},
      cafeProducts: {},
      mostPopularCafeProducts: {},
      hotCafeProducts: {},
      coldCafeProducts: {},
      cafeInfo: {},
      product: {},
      variations: {},
      addons: {},
      productOriginalPrice: null,
      productId: null,
      cartOrders: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      active: {
        product_drawer: false,
      },
    };
  },
  methods: {
    viewProduct(product) {
      this.product = product.cafeProducts;
      this.variations = product.cafeProducts.variations;
      this.addons = product.addons.name;
      this.productOriginalPrice = product.cafeProducts.originalPrice;
      this.productId = product.cafeProducts.id;

      this.active.product_drawer = true;
    },
    closeProductDrawer() {
      this.active.product_drawer = false;
    },
    async getProductVariations() {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = await axios.get(
        BASE_URI + "/product/get-product-variations",
        axiosConfig
      );

      this.productVariations =
        result && result.data.data.length !== null
          ? await Object.assign({}, result.data.data)
          : [];
    },
    async getCafeProductsByCafeId(cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = await axios.get(
        BASE_URI + "/product/get-cafe-products/" + cafeId,
        axiosConfig
      );

      for (let i = 0; i < result.data.data.length; i++) {
        this.cafeProducts[i] = {
          cafeProducts: result.data.data[i],
          addons: {
            name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
          },
        };

        if (
          result.data.data[i].is_popular == 1 &&
          Object.keys(this.mostPopularCafeProducts).length <= 6
        ) {
          this.mostPopularCafeProducts[i] = {
            cafeProducts: result.data.data[i],
            addons: {
              name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
            },
          };
        }

        if (result.data.data[i].category == "Hot") {
          this.hotCafeProducts[i] = {
            cafeProducts: result.data.data[i],
            addons: {
              name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
            },
          };
        }

        if (result.data.data[i].category == "Cold") {
          this.coldCafeProducts[i] = {
            cafeProducts: result.data.data[i],
            addons: {
              name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
            },
          };
        }
      }
    },
    async getCoffeeShopsById(cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let result = await axios.get(
        BASE_URI + "/cafe/get-coffee-shops/" + cafeId,
        axiosConfig
      );

      this.cafeInfo =
        result && result.data.data.length ? await result.data.data[0] : {};
    },
    async getCartOrders(userId, cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/cart/get-cart-orders/?userId=" +
          userId +
          "&cafeId=" +
          cafeId +
          "&status=pending"
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;
      }
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cafeId = urlParams.get("id") || "";
    const userId = localStorage.getItem("id") || null;
    localStorage.setItem("cafeId", cafeId);

    await this.getProductVariations();
    await this.getCafeProductsByCafeId(cafeId);
    await this.getCoffeeShopsById(cafeId);
    await this.getCartOrders(userId, cafeId);
  },
});
</script>
