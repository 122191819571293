<template>
  <ion-page>
    <!--inner header start-->
    <ion-header>
      <ion-toolbar>
        <ion-row>
          <ion-col size="4">
            <a href="/home">
              <ion-icon class="back-icon" src="assets/icon/arrow-left.svg" />
            </a>
          </ion-col>
          <ion-col size="4" class="h1-menu">Track your Order</ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="orders.length && orderStatus !== 'collected'">
      <div v-for="order in orders" :key="order.id">
        <ion-row class="orderlist">
          <ion-col size="2">
            <img :src="order.cafe.shopImgUrl" class="order-icon" />
          </ion-col>
          <ion-col class="order-col2">
            <a
              :href="
                '/order-details?cafeId=' +
                order.cafe.cafeId +
                '&cartOrderIds=' +
                order.cartOrderIds
              "
              style="text-decoration: none"
            >
              <ion-icon class="ver-icon" src="assets/icon/tick-circle.svg" />
              <span class="order-delivered">{{ order.status }}</span>
              <ion-icon
                src="assets/icon/dot-separate.svg"
                class="dotsep-icon"
              />
              <span class="order-txtlight">{{ order.quantity }} item/s</span>
              <span class="order-txtbold">{{ order.cafe.shopName }}</span>
            </a>
          </ion-col>
          <ion-col class="order-col3">
            <div class="ion-float-right">
              <span class="order-txtlight">
                {{ order.dateTimeOrdered }}
              </span>
              <span class="order-txtbold ion-text-right">
                $ {{ parseFloat(order.total).toFixed(2) }}
              </span>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
    <ion-content v-else>
      <div style="padding: 1em; text-align: center">No result/s found.</div>
    </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import {
  IonContent,
  IonHeader,
  IonButtons,
  IonIcon,
  IonToolbar,
  IonPage,
  IonRow,
  IonCol,
  IonAvatar,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "TrackOrderPage",
  components: {
    IonContent,
    IonHeader,
    IonButtons,
    IonIcon,
    IonToolbar,
    IonPage,
    IonRow,
    IonCol,
    IonAvatar,
  },
  data() {
    return {
      orders: [],
      orderId: "",
    };
  },
  methods: {
    async getOrders(userId, cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/orders/get-orders/?userId=" +
          userId +
          "&statuses=true" +
          "&cafeId=" +
          cafeId
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];
      this.orderId = this.orders[0].uuid;
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cafeId = urlParams.get("cafeId") || "";
    const userId = localStorage.getItem("id") || null;

    await this.getOrders(userId, cafeId);
  },
});
</script>
<style scoped>
ion-toolbar {
  --background: #ffffff;
  --border-color: #f3f3f3;
  --border-width: 1px 0;
}
.orderlist {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 15px;
  padding-bottom: 10px;
}
.back-icon {
  height: 20px;
  width: 20px;
  margin-top: 35%;
  margin-left: 10%;
}
.h1-menu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 42px;
  margin-bottom: 25px;
  text-align: center;
}
.h1-ol {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
  display: block;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 5%;
}
.order-icon {
  width: 46px;
  height: 46px;
  margin-left: 10px;
  margin-right: 10px;
}
.order-col2 {
  margin-left: 10px;
}
.order-col3 {
  margin-right: 10px;
}
.order-delivered {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  color: #00bf60;
  text-transform: capitalize;
}
.order-cancelled {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  color: #f04a3f;
}
.order-txtlight {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  color: #828282;
}
.order-txtbold {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 5px;
  padding-left: 5px;
}
.ver-icon {
  vertical-align: middle;
  padding-right: 5px;
}
</style>
