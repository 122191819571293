<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet name="order-tabs"></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="new-orders" href="/admin/new-orders">
          <ion-icon :icon="timer" />
          <ion-badge
            v-if="preparingOrders > 0"
            color="danger"
            class="notif-margin"
          >
            {{ preparingOrders }}
          </ion-badge>
          <ion-label>New Orders</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="confirmed-orders" href="/admin/confirmed-orders">
          <ion-icon :icon="checkmark" />
          <ion-badge v-if="readyOrders > 0" color="danger" class="notif-margin">
            {{ readyOrders }}
          </ion-badge>
          <ion-label>Confirmed Orders</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
  IonBadge,
  alertController,
} from "@ionic/vue";

import axios from "axios";
import { defineComponent } from "vue";
import { timer, refresh, checkmark, cafe } from "ionicons/icons";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "OrderTabs",
  components: {
    IonPage,
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonIcon,
    IonBadge,
  },
  data() {
    return {
      timer,
      refresh,
      checkmark,
      cafe,
      orders: [],
      promptMessage: "",
      preparingOrders: 0,
      readyOrders: 0,
      collectedOrders: 0,
      intervalnotif: null,
    };
  },
  methods: {
    async presentAlert(status) {
      const alert = await alertController.create({
        message: this.promptMessage,
      });

      await alert.present();
      await alert.onDidDismiss();
      if (status == 200 || status === 400) window.location.href = "/admin";
      else return;
    },
    async getAdminOrdersNotif(cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = BASE_URI + "/orders/admin/get-orders/?cafeId=" + cafeId;

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];

      if (this.orders.length) {
        for (let i = 0; i < this.orders.length; i++) {
          if (this.orders[i].orderStatus == "ready") this.readyOrders++;
          if (this.orders[i].orderStatus == "collected") this.collectedOrders++;
        }
      }
    },
    async refreshData(cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url =
        BASE_URI +
        "/orders/admin/get-orders/?cafeId=" +
        cafeId +
        "&status=preparing";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];
      this.preparingOrders = 0;

      if (this.orders.length) {
        for (let i = 0; i < this.orders.length; i++) {
          if (this.orders[i].orderStatus == "preparing") this.preparingOrders++;
        }
      }
      if (this.orders.length) {
        this.preparingOrders = this.orders.filter(
          (order) => order.orderStatus === "preparing"
        ).length;

        if (this.preparingOrders > 0) {
          const audio = new Audio("/assets/sounds/neworder.wav");
          audio.play();
        }
      }
    },
  },
  async created() {
    await this.getAdminOrdersNotif(localStorage.getItem("cafeId"));
    this.refreshData(localStorage.getItem("cafeId"));
    this.intervalnotif = setInterval(() => {
      this.refreshData(localStorage.getItem("cafeId"));
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.intervalnotif);
  },
});
</script>
<style scoped>
.notif-margin {
  margin-left: 5px;
}
</style>
