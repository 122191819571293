<template>
  <ion-page>
    <ion-content>
      <ion-row>
        <ion-col size="1">
          <a @click="$router.go(-1)">
            <ion-icon class="favback-icon" src="assets/icon/arrow-left.svg"
          /></a>
        </ion-col>
        <ion-col size="11">
          <ion-searchbar
            v-model="searchValue"
            class="search-bar"
            placeholder="Find a cafe"
            @keyup.enter="search"
          ></ion-searchbar>
        </ion-col>
      </ion-row>
      <div class="favorites-div">
        <!--<CategoryButtons name="CategoryButtons" />-->
        <span class="h1-search">Recent searches</span>
        <ion-row style="flex-wrap: nowrap; overflow: hidden; max-height: 70px">
          <ion-col size="12">
            <div v-for="search in searches" :key="search.id">
              <ion-button class="recent" @click="runSearch(search)">
                {{ search }}
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
        <hr class="sep-div" />
        <CoffeeShopCard :cafes="cafes" />
      </div>
    </ion-content>
  </ion-page>
</template>
<style scoped>
.h1-search {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 42px;
  margin-bottom: 25px;
  margin-left: 15px;
}
.favback-icon {
  height: 20px;
  width: 20px;
  margin-top: 33px;
  margin-left: 15px;
}
.favorites-div {
  padding: 5%;
}
ion-searchbar.search-bar {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  --background: #f3f3f3;
  --color: #828282;
  --placeholder-color: #828282;
  --icon-color: #1b1b1b;
  --clear-button-color: #828282;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 20px;
  margin-right: 15px;
  --border-radius: 2px;
  --box-shadow: none !important;
}
ion-searchbar.ios.search-bar {
  --cancel-button-color: #1b1b1b;
}
ion-searchbar.md.search-bar {
  --cancel-button-color: #1b1b1b;
}
.searchbar-input-container.sc-ion-searchbar-ios {
  height: 36px;
  background: #f3f3f3;
}
ion-button.recent {
  --background: #f3f3f3;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #666666;
  --border-radius: 8px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  --padding: 8px 16px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;
  margin-right: 15px;
  text-transform: none;
  --box-shadow: none;
  width: auto;
}
.sep-div {
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 20px;
}
</style>
<script>
import axios from "axios";
import { defineComponent } from "vue";
import CoffeeShopCard from "@/components/CoffeeShopCard.vue";
import {
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonButton,
  IonSearchbar,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "SearchPage",
  components: {
    IonContent,
    IonIcon,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonButton,
    IonSearchbar,
    CoffeeShopCard,
  },
  data() {
    return {
      searchValue: "",
      searches: [],
      cafes: [],
      userId: "",
    };
  },
  created() {
    this.userId = localStorage.getItem("id") || null;

    if (this.$route.query.q) {
      this.searchValue = this.$route.query.q;
      this.getRecentSearches();
      this.search();
    }
  },
  methods: {
    async getRecentSearches() {
      this.searches = [];
      let paramsData = {
        userId: this.userId,
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        params: paramsData,
      };

      let result = await axios.get(
        BASE_URI + "/search/get-searches",
        axiosConfig
      );

      result.data.data.forEach((element) => {
        this.searches.push(element.keyword);
      });
    },

    async search() {
      let paramsData = {
        isDeleted: "0",
        q: this.searchValue,
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        params: paramsData,
      };

      let result = await axios.get(
        BASE_URI + "/cafe/get-coffee-shops-with-details",
        axiosConfig
      );

      this.cafes = result.data.data;

      // This is where the search keyword is being stored in the database
      paramsData = {
        userId: this.userId,
        keyword: this.searchValue,
      };

      axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      await axios.post(
        BASE_URI + "/search/create-searches",
        paramsData,
        axiosConfig
      );
    },

    async handleKeyPress(event) {
      if (event.keyCode === 13) {
        await this.search();
      }
    },

    runSearch(searchValue) {
      this.searchValue = searchValue;
      this.getRecentSearches();
      this.search();
    },
  },
});
</script>
