<template>
  <ion-page>
    <!--inner header start-->
    <ion-header>
      <ion-toolbar>
        <ion-row>
          <ion-col size="2">
            <a @click="$router.go(-1)">
              <ion-icon class="am-back-icon" src="assets/icon/arrow-left.svg"
            /></a>
          </ion-col>
          <ion-col size="8" class="h1-menu">Address Manager</ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-row>
        <ion-col class="h1-am" size="9">My Location</ion-col>
        <ion-col class="edit-am" size="3">Edit</ion-col>
      </ion-row>

      <ion-row class="am-list" router-link="/">
        <ion-col size="2">
          <img src="assets/icon/home.png" class="am-icon" />
        </ion-col>
        <ion-col class="am-col2" size="8">
          <span class="am-txt1">Home</span>
          <span class="am-txt2">Add your home address</span>
        </ion-col>
        <ion-col class="am-col3">
          <div class="ion-float-right">
            <img src="assets/icon/arrow-right.png" class="ar-am" />
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="am-list" router-link="/">
        <ion-col size="2">
          <img src="assets/icon/company.png" class="am-icon" />
        </ion-col>
        <ion-col class="am-col2" size="8">
          <span class="am-txt1">Company</span>
          <span class="am-txt2">367C Magill Rd. St Morris SA 5069</span>
        </ion-col>
        <ion-col class="am-col3">
          <div class="ion-float-right">
            <img src="assets/icon/am-edit.png" class="ar-am" />
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="am-list" router-link="/">
        <ion-col size="2">
          <img src="assets/icon/location-am.png" class="am-icon" />
        </ion-col>
        <ion-col class="am-col2" size="8">
          <span class="am-txt1">Friends House</span>
          <span class="am-txt2">32412 Nulla St., Mankato Mississip...</span>
        </ion-col>
        <ion-col class="am-col3">
          <div class="ion-float-right">
            <img src="assets/icon/am-edit.png" class="ar-am" />
          </div>
        </ion-col>
      </ion-row>

      <ion-row router-link="/">
        <ion-col size="12">
          <img src="assets/icon/add-am.png" class="am-add-icon" />
          <span class="am-add">Add other address</span>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>
<style scoped>
ion-toolbar {
  --background: #ffffff;
  --border-color: #f3f3f3;
  --border-width: 1px 0;
}
.am-list {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 15px;
  padding-bottom: 10px;
}
.am-back-icon {
  height: 20px;
  width: 20px;
  margin-top: 75%;
  margin-left: 20%;
}
.h1-menu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 42px;
  margin-bottom: 25px;
  text-align: center;
}
.h1-am {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-left: 25px;
}
.edit-am {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ff8000;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-right: 15px;
  text-align: right;
}
.am-icon {
  width: 46px;
  height: 46px;
  margin-left: 15px;
  margin-right: 10px;
}
.am-col2 {
  margin-left: 10px;
}
.am-col3 {
  margin-right: 10px;
}
.am-txt1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.am-txt2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
  text-align: right;
}
.ar-am {
  margin-top: 12px;
  padding-right: 15px;
}
.am-add {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ff5500;
}
.am-add-icon {
  margin: 20px 10px 0px 20px;
}
</style>
<script>
import { defineComponent } from "vue";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonPage,
  IonRow,
  IonCol,
} from "@ionic/vue";

export default defineComponent({
  name: "AddressManagementPage",
  components: {
    IonContent,
    IonHeader,
    IonIcon,
    IonToolbar,
    IonPage,
    IonRow,
    IonCol,
  },
});
</script>
