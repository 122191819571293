<template>
  <ion-page>
    <!--inner header start-->
    <ion-header>
      <ion-row>
        <ion-col size="2">
          <a @click="$router.go(-1)">
            <ion-icon class="am-back-icon" src="assets/icon/arrow-left.svg"
          /></a>
        </ion-col>
        <ion-col size="8" class="h1-menu">Select Location</ion-col>
        <ion-col size="2">
          <a href="/home"
            ><img class="am-back-icon" src="assets/icon/map.png"
          /></a>
        </ion-col>
      </ion-row>
    </ion-header>
    <ion-searchbar
      class="location-search"
      placeholder="Enter your address"
    ></ion-searchbar>
    <ion-content>
      <ion-row>
        <ion-col class="h1-am" size="9">Recent location</ion-col>
        <ion-col class="edit-am" size="3">Clear</ion-col>
      </ion-row>
      <ion-row class="am-list" router-link="/">
        <ion-col size="2">
          <img src="assets/icon/select-location.png" class="am-icon" />
        </ion-col>
        <ion-col class="am-col2" size="9">
          <span class="am-txt1">Pennsylvania</span>
          <span class="am-txt2"
            >3517 W. Gray St. Utica, Pennsylvania 57867</span
          >
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="h1-am" size="12">Results</ion-col>
      </ion-row>
      <ion-row class="am-list" router-link="/">
        <ion-col class="am-col2" size="9">
          <span class="am-txt3">Cecilia Chapman</span>
          <span class="am-txt4"
            >3517 W. Gray St. Utica, Pennsylvania 57867</span
          >
        </ion-col>
        <ion-col size="2">
          <img src="assets/icon/archive-add.png" class="am-icon-save" />
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>
<style scoped>
ion-toolbar {
  --background: #ffffff;
  --border: none;
}
.am-list {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 15px;
  padding-bottom: 10px;
}
.am-back-icon {
  height: 20px;
  width: 20px;
  margin-top: 75%;
  margin-left: 20%;
}
.h1-menu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 42px;
  margin-bottom: 25px;
  text-align: center;
}
.h1-am {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-left: 25px;
}
.edit-am {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ff8000;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-right: 15px;
  text-align: right;
}
.am-icon {
  width: 46px;
  height: 46px;
  margin-left: 15px;
  margin-right: 10px;
}
.am-icon-save {
  margin-left: 40px;
  margin-top: 10px;
}
.am-col2 {
  margin-left: 10px;
}
.am-col3 {
  margin-right: 10px;
}
.am-txt1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.am-txt2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
  text-align: right;
}
.am-txt3 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
  padding-left: 15px;
}
.am-txt4 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
  text-align: right;
  padding-left: 15px;
}
.ar-am {
  margin-top: 12px;
  padding-right: 15px;
}
.am-add {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ff5500;
}
.am-add-icon {
  margin: 20px 10px 0px 20px;
}
.location-search {
  --background: #f3f3f3;
  --border-radius: 2px;
  --icon-color: #1b1b1b;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
</style>
<script>
import { defineComponent } from "vue";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonPage,
  IonRow,
  IonCol,
  IonSearchbar,
} from "@ionic/vue";

export default defineComponent({
  name: "SelectLocationPage",
  components: {
    IonContent,
    IonHeader,
    IonIcon,
    IonToolbar,
    IonPage,
    IonRow,
    IonCol,
    IonSearchbar,
  },
});
</script>
