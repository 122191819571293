<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="container-rewards">
        <router-link to="/home" replace>
          <img class="back-icon" src="assets/icon/arrowleft-circle.svg" />
        </router-link>
      </div>
      <div class="cafeinfo">
        <div class="cafeinfo-div">
          <ion-row class="orderlist" router-link="/order-details">
            <ion-col size="2">
              <img src="assets/icon/promo.png" class="rewards-icon" />
            </ion-col>
            <ion-col>
              <span class="rewards-txt-normal">Promotion</span>
              <span class="rewards-txtlight">Expires on</span>
              <ion-icon
                src="assets/icon/dot-separate.svg"
                class="dotsep-icon"
              />
              <span class="rewards-txtlight">11 May 2021</span>
            </ion-col>
          </ion-row>
          <h1 class="h1-rewards">Promotion title</h1>
        </div>
      </div>
      <div class="rewards-p">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
        molestie urna. In efficitur nisl et dapibus venenatis. Praesent finibus
        enim augue, sed pellentesque purus sollicitudin et. Praesent ac
        ultricies libero. Nulla ut faucibus ante, vitae malesuada elit. Nunc
        egestas ipsum non dolor ornare fringilla.
      </div>

      <div class="rewards-p">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
        molestie urna. In efficitur nisl et dapibus venenatis. Praesent finibus
        enim augue, sed pellentesque purus sollicitudin et. Praesent ac
        ultricies libero. Nulla ut faucibus ante, vitae malesuada elit. Nunc
        egestas ipsum non dolor ornare fringilla.
      </div>

      <div class="rewards-p">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
        molestie urna. In efficitur nisl et dapibus venenatis. Praesent finibus
        enim augue, sed pellentesque purus sollicitudin et. Praesent ac
        ultricies libero. Nulla ut faucibus ante, vitae malesuada elit. Nunc
        egestas ipsum non dolor ornare fringilla.
      </div>
    </ion-content>
    <!-- content end -->
  </ion-page>
</template>
<style scoped>
.container-rewards {
  position: relative;
  height: 160px;
  background: #ff8000;
}
.container-rewards .back-icon {
  position: absolute;
  top: 60px;
  left: 20px;
}
.back-icon {
  height: 36px;
  width: 36px;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 11px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.cafeinfo-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
}
.cafeinfo-div {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 5%;
  margin-top: -70px;
  z-index: 2;
  position: relative;
}
.cafeinfo {
  padding: 5%;
}
.cafeinfo2 {
  padding: 0% 5% 5% 5%;
}
.h1-rewards {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b1b;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  text-align: left;
}
.cafeinfo-p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  color: #828282;
}
.rewards-txt-normal {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  margin-top: 15px;
  margin-left: 5px;
}
.rewards-txtlight {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  color: #828282;
  margin-top: 15px;
  margin-left: 5px;
}
.rewards-icon {
  margin-top: 10px;
}
.rewards-p {
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
}
</style>
<script>
import { defineComponent } from "vue";
import { IonPage, IonContent, IonRow, IonCol, IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "RewardInfoPage",
  components: { IonPage, IonContent, IonRow, IonCol, IonIcon },
});
</script>
