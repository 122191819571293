<template>
  <ion-page>
    <ion-content>
      <ion-row>
        <ion-col size="4">
          <a @click="$router.go(-1)">
            <ion-icon class="favback-icon" src="assets/icon/arrow-left.svg"
          /></a>
        </ion-col>
        <ion-col size="4" class="h1-menu">My Favorites</ion-col>
      </ion-row>
      <div style="padding-left: 2em">
        <CoffeeShopCard :cafes="cafes" />
      </div>
    </ion-content>
  </ion-page>
</template>
<style scoped>
.center-img {
  display: flex;
  justify-content: center;
}
.fav-card {
  margin-bottom: 10px;
  height: 100px;
}
.h1-menu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 42px;
  margin-bottom: 25px;
  text-align: center;
}
.fav-h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
  display: block;
}
.favback-icon {
  height: 20px;
  width: 20px;
  margin-top: 35%;
  margin-left: 20%;
}
.favorites-div {
  padding: 5%;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.img-container img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.rating-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
}
.fav-cafeinfo {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #828282;
  display: block;
  margin-bottom: 5px;
}
.fav-cafeinfo2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #828282;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 11px;
}
</style>
<script>
import axios from "axios";
import { defineComponent } from "vue";
import CoffeeShopCard from "@/components/CoffeeShopCard.vue";
import {
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonButton,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "FavoritesPage",
  components: {
    IonContent,
    IonIcon,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonButton,
    CoffeeShopCard,
  },
  data() {
    return {
      cafes: [],
      userId: "",
    };
  },
  created() {
    this.userId = localStorage.getItem("id") || null;
    this.getFavorites();
  },
  methods: {
    async getFavorites() {
      this.cafes = [];
      let paramsData = {
        userId: this.userId,
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        params: paramsData,
      };

      let result = await axios.get(
        BASE_URI + "/favorites/get-favorites-with-details",
        axiosConfig
      );
      this.cafes = result.data.data;
    },
  },
});
</script>
