<template>
  <div>
    <ion-menu content-id="main-content" side="end">
      <ion-header>
        <ion-toolbar class="toolbar-inner">
          <ion-menu-toggle></ion-menu-toggle>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-row>
          <ion-col size="4">
            <ion-menu-toggle>
              <ion-icon class="back-icon" src="assets/icon/arrow-left.svg" />
            </ion-menu-toggle>
          </ion-col>
          <ion-col size="4" class="h1-menu">Admin Menu</ion-col>
          <ion-col size="4">
            <ion-icon class="right-icon" src="assets/icon/moon-outline.svg" />
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="3">
            <ion-avatar class="avatar-menu">
              <img src="assets/icon/avatar.svg" />
            </ion-avatar>
          </ion-col>
          <ion-col size="7">
            <span class="name-menu">{{ fullName }}</span>
            <span class="edit-menu"><a href="/profile">Edit profile</a> </span>
          </ion-col>
          <ion-col size="2">
            <ion-icon class="right-icon2" src="assets/icon/eye-outline.svg" />
          </ion-col>
        </ion-row>

        <ion-list>
          <div class="sidemenu">
            <ion-row>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/lovely-menu.svg" />
              </ion-col>
              <ion-col size="10" class="ion-padding-start">
                <a href="/admin">Order Management</a>
              </ion-col>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/arrow-right.svg" />
              </ion-col>
            </ion-row>
          </div>
          <div class="sidemenu">
            <ion-row>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/map-menu.svg" />
              </ion-col>
              <ion-col size="10" class="ion-padding-start">
                <a href="/admin/order-history">Order History</a>
              </ion-col>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/arrow-right.svg" />
              </ion-col>
            </ion-row>
          </div>
          <!--<div class="sidemenu">
            <ion-row>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/wallet-menu.svg" />
              </ion-col>
              <ion-col size="10" class="ion-padding-start">
                <router-link to="/admin">Item 3</router-link>
              </ion-col>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/arrow-right.svg" />
              </ion-col>
            </ion-row>
          </div>
          <div class="sidemenu">
            <ion-row>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/ticket-menu.svg" />
              </ion-col>
              <ion-col size="10" class="ion-padding-start">
                <router-link to="/admin"> Item 4 </router-link>
              </ion-col>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/arrow-right.svg" />
              </ion-col>
            </ion-row>
          </div>
          <div class="sidemenu">
            <ion-row>
              <ion-col size="1">
                <ion-icon
                  class="list-icon"
                  src="assets/icon/receipt-minus-menu.svg"
                />
              </ion-col>
              <ion-col size="10" class="ion-padding-start">
                <router-link to="/admin">Item 5</router-link>
              </ion-col>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/arrow-right.svg" />
              </ion-col>
            </ion-row>
          </div>
          <div class="sidemenu">
            <ion-row>
              <ion-col size="1">
                <ion-icon
                  class="list-icon"
                  src="assets/icon/message-question-menu.svg"
                />
              </ion-col>
              <ion-col size="10" class="ion-padding-start">
                <router-link to="/admin">Item 6</router-link>
              </ion-col>
              <ion-col size="1">
                <ion-icon class="list-icon" src="assets/icon/arrow-right.svg" />
              </ion-col>
            </ion-row>
          </div>-->
        </ion-list>
      </ion-content>
      <div align="center" class="logout-menu padbot-logout">
        <router-link to="" @click="logout">Log Out</router-link>
      </div>
    </ion-menu>
    <div id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonMenuToggle,
  IonCol,
  IonRow,
  IonAvatar,
  IonList,
  alertController,
} from "@ionic/vue";

export default defineComponent({
  name: "AdminHeaderMenu",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenu,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonMenuToggle,
    IonCol,
    IonRow,
    IonAvatar,
    IonList,
  },
  data() {
    return {
      promptMessage: "",
      fullName:
        localStorage.getItem("name") !== undefined
          ? localStorage.getItem("name")
          : "John Doe",
    };
  },
  methods: {
    async presentAlert() {
      const alert = await alertController.create({
        message: this.promptMessage,
        buttons: [
          {
            text: "No",
            role: "cancel",
            handler: () => {},
          },
          {
            text: "Yes",
            role: "confirm",
            handler: () => {
              this.onConfirmOK(false);
            },
          },
        ],
      });

      await alert.present();
      await alert.onDidDismiss();
    },
    async logout() {
      this.promptMessage = "Are you sure you want to logout?";
      this.presentAlert();
    },
    onConfirmOK() {
      localStorage.clear();
      window.location.href = "/userlogin";
    },
  },
});
</script>
<style scoped>
.home-header {
  background-color: #ff8000;
  border: solid 1px #ff8000;
}
.header-toolbar {
  border: none;
}
ion-menu {
  --width: 100%;
}
ion-menu-button {
  --color: #ffffff;
  width: 40px;
  height: 40px;
  margin-top: 12px;
  margin-right: 8px;
}
ion-menu-button.ios.button {
  --color: #ffffff;
  width: 40px;
  height: 40px;
  margin-top: 7px;
  margin-right: 8px;
  margin-bottom: 7px;
}
ion-toolbar {
  --background: #ff8000;
}
ion-toolbar.toolbar-inner {
  --background: #ffffff;
}
.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0px;
}
.back-icon {
  height: 20px;
  width: 20px;
  margin-left: 10%;
}
.right-icon {
  height: 20px;
  width: 20px;
  float: right;
  margin-right: 10%;
  margin-bottom: 25px;
}
.h1-menu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-bottom: 25px;
  text-align: center;
}
.name-menu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
  display: block;
  margin-top: 5px;
}
.edit-menu a {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  color: #ababab;
  display: block;
  margin-top: 5px;
  text-decoration: none;
}
.avatar-menu {
  margin-left: 10%;
}
.right-icon2 {
  height: 20px;
  width: 20px;
  float: right;
  margin-right: 45%;
  margin-top: 10px;
  margin-bottom: 30px;
}
.sidemenu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  color: #1b1b1b;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f3;
  padding-left: 20px;
  padding-right: 20px;
}
.sidemenu a {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  color: #1b1b1b;
  font-size: 14px;
  text-decoration: none;
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.list-icon {
  width: 14px;
}
.logout-menu a {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ff8000;
  text-decoration: none;
}
.padbot-logout {
  padding-bottom: 32px;
}
</style>
