<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="order-status">
        <div align="center" class="qr-container">
          <img src="assets/placeholders/qr.png" />
          <h1 class="h1-tracking">{{ userName }}</h1>
          <h2 class="h2-tracking">
            {{ orderId.replace(/-/gi, "").toUpperCase() }}
          </h2>
        </div>
        <div class="orderstatus-div">
          <ion-row class="row-orderlist">
            <ion-col size="12">
              <span class="orderstatus-h1"
                >Your order is ready for pickup!</span
              >
            </ion-col>
          </ion-row>
        </div>

        <div class="orderstatus-div ion-margin-top">
          <ion-row
            class="row-orderlist"
            v-for="orders in cartOrders"
            :key="orders.id"
          >
            <ion-col size="2">
              <span class="order-status-bold">{{ orders.quantity }} x</span>
            </ion-col>
            <ion-col>
              <span class="order-status-bold">
                {{ orders.product.productName }}
              </span>
              <div v-for="variations in orders.variations" :key="variations.id">
                <span class="order-status-txtlight dblock">
                  {{ variations.name }}
                </span>
              </div>
            </ion-col>
            <ion-col>
              <span class="order-status-normal position-right">
                $ {{ orders.pricePerOrder.toFixed(2) }}
              </span>
            </ion-col>
          </ion-row>
          <ion-button
            expand="block"
            color="primary"
            class="orderstatus-btn"
            @click="finishOrder"
          >
            Finish Order
          </ion-button>
        </div>
      </div>
    </ion-content>
    <!-- content end -->
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import {
  IonPage,
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  alertController,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "OrderTrackingPage",
  components: { IonPage, IonContent, IonRow, IonCol, IonIcon, IonButton },
  data() {
    return {
      cartOrders: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      serviceFee: 0,
      totalCartPrice: 0,
      cafeId: "",
      cafeName: "",
      cafeAddress: "",
      orders: [],
      orderId: "",
      userName: "",
      promptMessage: "",
    };
  },
  methods: {
    async presentAlert(status) {
      const alert = await alertController.create({
        message: this.promptMessage,
      });

      await alert.present();
      await alert.onDidDismiss();
      if (status == 200 || status === 400) window.location.href = "/home";
      else return;
    },
    async getCartOrders(userId, cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/cart/get-cart-orders/?userId=" +
          userId +
          "&status=order_placed" +
          "&cafeId=" +
          cafeId
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;
      }

      this.serviceFee = this.totalCartQty * 0.5;
      this.totalCartPrice = this.subTotalCartPrice + this.serviceFee;

      this.cafeId = this.cartOrders[0].cafe.cafeId;
      this.cafeName = this.cartOrders[0].cafe.shopName;
      this.cafeAddress = this.cartOrders[0].cafe.address;
    },
    async getOrders(userId, cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/orders/get-orders/?userId=" +
          userId +
          "&status=preparing" +
          "&cafeId=" +
          cafeId
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available anymore.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];
      this.orderId = this.orders[0].uuid;
    },
    async finishOrder() {
      let updateOrderData = {
        orderId: this.orderId,
        status: "collected",
      };

      let updateCartOrderData = {
        userId: localStorage.getItem("id"),
        cafeId: this.cafeId,
        status: "collected",
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let orderResult = await axios.put(
        BASE_URI + "/orders/update-order",
        updateOrderData,
        axiosConfig
      );

      let cartOrderResult = await axios.put(
        BASE_URI + "/cart/update-cart-orders",
        updateCartOrderData,
        axiosConfig
      );

      if (orderResult.status === 200 && cartOrderResult.status === 200) {
        this.promptMessage = "Order has been collected.";
        await this.presentAlert(orderResult.status);
      } else {
        this.promptMessage = orderResult.data.error;
        await this.presentAlert(orderResult.status);
      }
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cafeId = urlParams.get("cafeId") || "";
    const userId = localStorage.getItem("id") || null;
    this.userName = localStorage.getItem("name") || null;

    if (cafeId == "") window.location.href = "/cart";
    else {
      await this.getCartOrders(userId, cafeId);
      await this.getOrders(userId, cafeId);
    }
  },
});
</script>
<style scoped>
.h1-tracking {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #1b1b1b;
}
.h2-tracking {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ff8000;
  margin-bottom: 10%;
}
.qr-container {
  margin-top: 15%;
}
.dblock {
  display: block;
}
.orderstatus-div {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-top: 5%;
  padding-bottom: 5%;
}
.row-orderlist {
  padding-left: 7%;
  padding-right: 7%;
}
.orderstatus-h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
}
.order-status {
  padding: 5%;
}
.order-status-txtlight {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
}
.order-status-bold {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
}
.order-status-normal {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
}
.position-right {
  position: absolute;
  right: 6px;
}
.orderstatus-maps {
  padding: 0%;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 185px;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  margin-top: 16px;
  height: 52px;
  text-transform: none;
  --box-shadow: none;
}
.orderstatus-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}
</style>
