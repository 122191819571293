<template>
  <ion-page>
    <AdminHeaderMenu name="AdminHeaderMenu" />
    <ion-content>
      <OrderTabs name="OrderTabs" />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import AdminHeaderMenu from "@/components/admin/AdminHeaderMenu.vue";
import OrderTabs from "@/components/admin/tabs/OrderTabs.vue";

import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonHeader,
  IonItem,
  IonButtons,
  IonMenuButton,
  IonList,
  IonIcon,
  IonToolbar,
  IonMenu,
} from "@ionic/vue";

export default defineComponent({
  name: "AdminHomeLayout",
  components: {
    AdminHeaderMenu,
    OrderTabs,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonPage,
    IonContent,
    IonHeader,
    IonItem,
    IonButtons,
    IonMenuButton,
    IonList,
    IonIcon,
    IonToolbar,
    IonMenu,
  },
});
</script>
<style scoped>
hr.solid {
  border-top: 1px solid #f3f3f3;
  margin-bottom: 10px;
}
</style>
