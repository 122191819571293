<template>
  <ion-page>
    <!--inner header start-->
    <ion-header>
      <ion-toolbar>
        <ion-row>
          <ion-col size="4">
            <a href="/home">
              <ion-icon class="back-icon" src="assets/icon/arrow-left.svg" />
            </a>
          </ion-col>
          <ion-col size="4" class="h1-cart">My Cart</ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="!cartOrders.length">
      <div style="text-align: center; margin-top: 1em">Your cart is empty.</div>
    </ion-content>
    <ion-content v-else>
      <div v-for="cart in cartOrders" :key="cart.id">
        <ion-row class="cartlist">
          <ion-col size="3">
            <img :src="cart.cafe.shopImgUrl" class="order-icon" />
          </ion-col>
          <ion-col class="cart-col2" size="7">
            <a
              :href="'/checkout?cafeId=' + cart.cafe.cafeId"
              style="text-decoration: none"
            >
              <span class="cart-txtbold">{{ cart.cafe.shopName }}</span>
              <span class="cart-txtlight">{{ cart.quantity }} item/s</span>
              <ion-icon
                src="assets/icon/dot-separate.svg"
                class="dotsep-icon"
              />
              <ion-icon
                src="assets/icon/time-outline.svg"
                class="banner-icon"
              ></ion-icon>
              <span class="cart-txtlight">20 min</span>
              <ion-icon
                src="assets/icon/dot-separate.svg"
                class="dotsep-icon"
              />
              <ion-icon
                src="assets/icon/distance.svg"
                class="banner-icon"
              ></ion-icon>
              <span class="cart-txtlight">3km</span>
            </a>
          </ion-col>
          <ion-col class="cart-col2">
            <a @click="presentAlert(cart.cafe.cafeId)">
              <img
                src="assets/icon/trash.png"
                class="trash-icon"
                color="primary"
              />
            </a>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
  </ion-page>
</template>
<style scoped>
ion-toolbar {
  --background: #ffffff;
  --border-color: #f3f3f3;
  --border-width: 1px 0;
}
.cartlist {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 15px;
  padding-bottom: 10px;
}
.back-icon {
  height: 20px;
  width: 20px;
  margin-top: 35%;
  margin-left: 10%;
}
.h1-cart {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 42px;
  margin-bottom: 25px;
  text-align: center;
}
.order-icon {
  width: 46px;
  height: 46px;
  margin-left: 30px;
  margin-right: 10px;
}
.cart-col2 {
  margin-left: 10px;
}
.cart-txtlight {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  color: #828282;
}
.cart-txtbold {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 5px;
  padding-left: 5px;
}
.trash-icon {
  width: 16px;
  margin-top: 15px;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 11px;
}
</style>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import {
  IonContent,
  IonHeader,
  IonButtons,
  IonIcon,
  IonToolbar,
  IonPage,
  IonRow,
  IonCol,
  IonAvatar,
  alertController,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "CartPage",
  components: {
    IonContent,
    IonHeader,
    IonButtons,
    IonIcon,
    IonToolbar,
    IonPage,
    IonRow,
    IonCol,
    IonAvatar,
  },
  data() {
    return {
      cartOrders: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      serviceFee: 0,
      totalCartPrice: 0,
      promptMessage: "",
    };
  },
  methods: {
    async presentAlert(id) {
      if (id) {
        const alert = await alertController.create({
          subHeader: "Are you sure you want to delete this restaurant?",
          buttons: [
            {
              text: "No",
              role: "cancel",
              handler: () => {},
            },
            {
              text: "Yes",
              role: "confirm",
              handler: () => {
                this.removeCartOrder(id);
              },
            },
          ],
        });

        await alert.present();
        await alert.onDidDismiss();
      } else {
        const alert = await alertController.create({
          message: this.promptMessage,
        });

        await alert.present();
        await alert.onDidDismiss();
        window.location.reload();
      }
    },
    async getCartOrders(userId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/cart/get-cart-orders/?userId=" +
          userId +
          "&status=pending" +
          "&groupByCafeId=true"
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;
      }

      this.serviceFee = this.totalCartQty * 0.5;
      this.totalCartPrice = this.subTotalCartPrice + this.serviceFee;
    },
    async removeCartOrder(cafeId) {
      let headers = {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      };

      let paramsData = {
        userId: localStorage.getItem("id"),
        cafeId: cafeId,
      };

      await axios
        .delete(BASE_URI + "/cart/delete-cart-order", {
          headers,
          data: paramsData,
        })
        .then((res) => {
          this.promptMessage = res.data.message;
          this.presentAlert();
        })
        .catch((err) => {
          throw err.response.status;
        });
    },
  },
  async created() {
    const userId = localStorage.getItem("id") || null;
    await this.getCartOrders(userId);
  },
});
</script>
