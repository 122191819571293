<template>
  <div>
    <div class="tab-wrap tabset">
      <input type="radio" id="tab1" name="hometabs" class="tab" checked />
      <label for="tab1">Popular</label>
      <input type="radio" id="tab2" name="hometabs" class="tab" />
      <label for="tab2">Previous</label>
      <input type="radio" id="tab3" name="hometabs" class="tab" />
      <label for="tab3">Favorites</label>
      <div class="tab-content" id="popular">
        <div v-if="coffeeShops.length">
          <ion-card class="cafelist" v-for="cafe in coffeeShops" :key="cafe.id">
            <a :href="'/cafemenu?id=' + cafe.uuid">
              <div class="img-container">
                <img v-bind:src="cafe.shopImgUrl" class="cafelist-img" />
              </div>
            </a>
            <h2 class="h2-banner">{{ cafe.shopName }}</h2>
            <ion-icon
              src="assets/icon/star.svg"
              class="banner-icon"
              color="primary"
            >
            </ion-icon>
            <span class="rating-txt">{{ cafe.averageRatings }}</span>
            <span class="cafeinfo-txt"> (232)</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            >
            </ion-icon>
            <ion-icon
              src="assets/icon/distance.svg"
              class="banner-icon"
              color="primary"
            >
            </ion-icon>
            <span class="cafeinfo-txt">1.3km</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            >
            </ion-icon>
            <ion-icon src="assets/icon/time-outline.svg" class="banner-icon">
            </ion-icon>
            <span class="cafeinfo-txt">24 min</span>
          </ion-card>
        </div>
        <div v-else class="center-img">
          <img src="assets/icon/empty-message.png" />
          <h1 class="no-result">No results found</h1>
        </div>
      </div>
      <div class="tab-content" id="previous">
        <div v-if="coffeeShops.length">
          <ion-card class="cafelist" v-for="cafe in coffeeShops" :key="cafe.id">
            <a :href="'/cafemenu?id=' + cafe.uuid">
              <div class="img-container">
                <img v-bind:src="cafe.shopImgUrl" class="cafelist-img" />
              </div>
            </a>
            <h2 class="h2-banner">{{ cafe.shopName }}</h2>
            <ion-icon
              src="assets/icon/star.svg"
              class="banner-icon"
              color="primary"
            >
            </ion-icon>
            <span class="rating-txt">{{ cafe.averageRatings }}</span>
            <span class="cafeinfo-txt"> (232)</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            >
            </ion-icon>
            <ion-icon
              src="assets/icon/distance.svg"
              class="banner-icon"
              color="primary"
            >
            </ion-icon>
            <span class="cafeinfo-txt">1.3km</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            >
            </ion-icon>
            <ion-icon src="assets/icon/time-outline.svg" class="banner-icon">
            </ion-icon>
            <span class="cafeinfo-txt">24 min</span>
          </ion-card>
        </div>
        <div v-else class="center-img">
          <img src="assets/icon/empty-message.png" />
          <h1 class="no-result">No results found</h1>
        </div>
      </div>
      <div class="tab-content" id="favorites">
        <div v-if="faveCoffeeShops.length">
          <ion-card
            class="cafelist"
            v-for="faveCafe in faveCoffeeShops"
            :key="faveCafe.id"
          >
            <a :href="'/cafemenu?id=' + faveCafe.cafeId">
              <div class="img-container">
                <img v-bind:src="faveCafe.shopImgUrl" class="cafelist-img" />
              </div>
            </a>
            <h2 class="h2-banner">{{ faveCafe.shopName }}</h2>
            <ion-icon
              src="assets/icon/star.svg"
              class="banner-icon"
              color="primary"
            >
            </ion-icon>
            <span class="rating-txt">{{ faveCafe.averageRatings }}</span>
            <span class="cafeinfo-txt"> (232)</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            >
            </ion-icon>
            <ion-icon
              src="assets/icon/distance.svg"
              class="banner-icon"
              color="primary"
            >
            </ion-icon>
            <span class="cafeinfo-txt">1.3km</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            >
            </ion-icon>
            <ion-icon src="assets/icon/time-outline.svg" class="banner-icon">
            </ion-icon>
            <span class="cafeinfo-txt">24 min</span>
          </ion-card>
        </div>
        <div v-else class="center-img">
          <img src="assets/icon/empty-message.png" />
          <h1 class="no-result">No results found</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.h1-banner {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
  margin: 15px 0px 15px 15px;
}
.link-banner {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #0057ff;
  float: right;
  padding-right: 15px;
  padding-top: 3px;
}
.h2-banner {
  font-family: "Gilroy";
  font-style: normal;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
}
a.link-banner {
  text-decoration: none;
}
.rating-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
}
.cafeinfo-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #828282;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 11px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.cafelist {
  border-radius: 2px;
  box-shadow: none;
}
.cafelist-img {
  border: none;
  box-shadow: none;
  border-radius: 8px;
  width: auto;
  height: 128px;
  object-fit: cover;
  margin-bottom: 10px;
}
ion-card {
  margin-top: 5px;
  margin-bottom: 10px;
}
div.img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.img-container img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
/*
 Tab new start
*/
.tab-wrap {
  -webkit-transition: 0.3s box-shadow ease;
  transition: 0.3s box-shadow ease;
  border-radius: 6px;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
  background-color: #fff;
  margin-bottom: 4em;
}
.tab {
  display: none;
}
.tab:checked:nth-of-type(1) ~ .tab-content:nth-of-type(1) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(2) ~ .tab-content:nth-of-type(2) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(3) ~ .tab-content:nth-of-type(3) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(4) ~ .tab-content:nth-of-type(4) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(5) ~ .tab-content:nth-of-type(5) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked + label {
  box-shadow: 0 -1px 0 #fff inset;
  cursor: default;
  color: #ffffff;
  background: #ff8505;
}
.tab:checked + label:hover {
  box-shadow: 0 -1px 0 #fff inset;
  color: #ffffff;
  background: #ff8505;
}
.tab + label {
  box-shadow: 0 -1px 0 #eee inset;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  display: inline-block;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #1b1b1b;
  margin: 10px 10px 10px 10px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  -webkit-box-flex: 3;
  -webkit-flex-grow: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
  text-align: center;
  background-color: #f2f2f2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  -webkit-transition: 0.3s background-color ease, 0.3s box-shadow ease;
  transition: 0.3s background-color ease, 0.3s box-shadow ease;
  height: 40px;
  box-sizing: border-box;
}
.tab + label:hover {
  color: #ffffff;
  background: #ff8505;
}
.tab-content {
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: -1;
  opacity: 0;
  left: 0;
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
  border-radius: 6px;
}
.center-img {
  text-align: center;
  display: block !important;
}
.no-result {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  margin-top: 0px;
  margin-bottom: 5px;
  display: block;
}
</style>
<script>
import { defineComponent } from "vue";
import {
  IonIcon,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonCard,
} from "@ionic/vue";

export default defineComponent({
  props: {
    coffeeShops: Object,
    faveCoffeeShops: Object,
  },
  components: {
    IonIcon,
    IonRow,
    IonCol,
    IonButton,
    IonButtons,
    IonCard,
  },
  methods: {
    setFocus: function () {
      window.addEventListener("load", function () {
        document.getElementById("tab1").focus();
      });
    },
  },
  mounted: function () {
    this.setFocus();
  },
});
</script>
