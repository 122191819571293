<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="container-support">
        <ion-row>
          <ion-col size="4">
            <router-link to="/support" replace
              ><ion-icon class="back-icon" src="assets/icon/arrow-left.svg"
            /></router-link>
          </ion-col>
          <ion-col size="8">
            <span class="h1-od">Support center</span>
          </ion-col>
        </ion-row>
      </div>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="12">
          <span class="support-heading">Guidelines</span>
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">How to connect wallet</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">However a small line</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Can I use multiple discount codes?</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Will I be charged for an exchange?</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Do you accept replacements?</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt"
            >Will I be charged for a replacement item?</span
          >
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">How do I make an exchange?</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">When will I be charged for my order?</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Are there any return exclusions?</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-post" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Whats your return policy?</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <hr class="support-row" />
      <!-- content end -->
    </ion-content>
  </ion-page>
</template>
<style scoped>
.container-support {
  padding-top: 10%;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
}
.back-icon {
  height: 20px;
  width: 20px;
}
.h1-od {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #1b1b1b;
}
.support-row {
  border-top: 1px solid #f3f3f3;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}
.support-heading {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.support-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.arrowright-icon {
  text-align: right;
  padding-top: 8px;
}
.position-right {
  position: absolute;
  right: 20px;
}
</style>
<script>
import { defineComponent } from "vue";
import {
  IonRow,
  IonCol,
  IonButton,
  IonPage,
  IonContent,
  IonIcon,
} from "@ionic/vue";

export default defineComponent({
  name: "SupportListPage",
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonPage,
    IonContent,
    IonIcon,
  },
});
</script>
