<template>
  <div v-if="cafes.length > 0">
    <div v-for="cafe in cafes" :key="cafe.id">
      <router-link :to="'cafemenu?id=' + cafe.cafeId">
        <ion-row class="fav-card">
          <ion-col size="5">
            <div class="img-container">
              <img :src="cafe.image" alt="Cafe Image" class="cafe-img" />
            </div>
          </ion-col>
          <ion-col size="7">
            <span class="fav-h2">{{ cafe.name }}</span>
            <span class="fav-cafeinfo">{{ cafe.details }}</span>
            <ion-icon
              src="assets/icon/star.svg"
              class="banner-icon"
              color="primary"
            ></ion-icon>
            <span class="rating-txt">{{ cafe.rating }}</span
            ><span class="fav-cafeinfo2"> ({{ cafe.reviews }})</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            ></ion-icon>
            <ion-icon
              src="assets/icon/distance.svg"
              class="banner-icon"
              color="primary"
            ></ion-icon>
            <span class="fav-cafeinfo2">{{ cafe.distance }}</span>
          </ion-col>
        </ion-row>
      </router-link>
    </div>
  </div>
  <div v-else class="center-img">
    <img src="assets/icon/empty-message.png" />
    <h1 class="no-result">No results found</h1>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  // IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonButton,
  IonSearchbar,
} from "@ionic/vue";

export default defineComponent({
  name: "CoffeeShopCard",
  components: {
    // IonContent,
    IonIcon,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonButton,
    IonSearchbar,
  },
  props: {
    cafes: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style scoped>
a {
  text-decoration: none;
}
.fav-card {
  margin-bottom: 10px;
  height: 100px;
}
.fav-h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
  display: block;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.img-container img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.fav-cafeinfo {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #828282;
  display: block;
  margin-bottom: 5px;
}
.fav-cafeinfo2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #828282;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 11px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.rating-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
}
.cafe-img {
  width: 100%;
  height: 100px; /* set the height as per your requirement */
  object-fit: cover;
}
.center-img {
  text-align: center;
  display: block !important;
}
.no-result {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  margin-top: 0px;
  margin-bottom: 5px;
  display: block;
}
</style>
