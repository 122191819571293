<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="container-support">
        <ion-row>
          <ion-col size="4">
            <router-link to="/support-list" replace
              ><ion-icon class="back-icon" src="assets/icon/arrow-left.svg"
            /></router-link>
          </ion-col>
          <ion-col size="8">
            <span class="h1-od">Support center</span>
          </ion-col>
        </ion-row>
      </div>
      <ion-row router-link="/support" class="support-row">
        <ion-col size="12">
          <span class="support-heading">How to connect wallet</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p class="support-p">
            Even the all-powerful Pointing has no control bout the blind texts
            it is an almost unorthographic life One day however a small line of
            blind text by the name of Lorem Ipsum decided to leave for the far
            World of Grammar.
          </p>
          <p class="support-p">
            Even the all-powerful Pointing has no control bout the blind texts
            it is an almost unorthographic life One day however a small line of
            blind text by the name of Lorem Ipsum decided to leave for the far
            World of Grammar.
          </p>
        </ion-col>
      </ion-row>
      <hr class="support-row" />
      <!-- content end -->
    </ion-content>
  </ion-page>
</template>
<style scoped>
.container-support {
  padding-top: 10%;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
}
.back-icon {
  height: 20px;
  width: 20px;
}
.h1-od {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #1b1b1b;
}
.support-row {
  border-top: 1px solid #f3f3f3;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}
.support-heading {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.support-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.arrowright-icon {
  text-align: right;
  padding-top: 8px;
}
.position-right {
  position: absolute;
  right: 20px;
}
.support-p {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  padding-left: 5%;
  padding-right: 5%;
}
</style>
<script>
import { defineComponent } from "vue";
import {
  IonRow,
  IonCol,
  IonButton,
  IonPage,
  IonContent,
  IonIcon,
} from "@ionic/vue";

export default defineComponent({
  name: "SupportListPage",
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonPage,
    IonContent,
    IonIcon,
  },
});
</script>
