<template>
  <ion-page>
    <!--inner header start-->
    <ion-header>
      <ion-toolbar>
        <ion-row>
          <ion-col size="4">
            <a href="/home">
              <ion-icon class="back-icon" src="assets/icon/arrow-left.svg" />
            </a>
          </ion-col>
          <ion-col size="4" class="h1-menu">Rewards</ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-row class="redeem-row">
        <ion-col size="8" class="redeem-col">
          <ion-item lines="none">
            <ion-input
              class="input-code"
              placeholder="Redeem your promo code here"
              type="text"
              fill="solid"
            />
          </ion-item>
        </ion-col>
        <ion-col size="4" class="redeem-col2">
          <ion-button style="width: 80%">Redeem</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="h1-sub" size="12">Active</ion-col>
      </ion-row>
      <div v-if="rewardsList.length > 0">
        <div v-for="rewards in rewardsList" :key="rewards.id">
          <ion-row class="rewardlist">
            <ion-col size="2" style="margin-top: 20px">
              <img src="assets/icon/promo.png" class="reward-icon" />
            </ion-col>
            <ion-col class="reward-col2" size="7">
              <span class="reward-resto">
                {{ rewards.cafe.shopName }}
              </span>
              <span class="reward-txtbold"> {{ rewards.title }} </span>
              <span class="reward-txtlight"> {{ rewards.description }} </span>
              <br />
              <span class="reward-expiry">
                Expires on {{ new Date(rewards.expiryDate).toDateString() }}
              </span>
            </ion-col>
            <ion-col size="2">
              <span class="promo-count">{{ rewards.feat }}</span>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else class="center-img">
        <img src="assets/icon/wallet.png" />
        <h1 class="no-result">No results found</h1>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import {
  IonContent,
  IonHeader,
  IonButtons,
  IonIcon,
  IonToolbar,
  IonPage,
  IonRow,
  IonCol,
  IonAvatar,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "RewardsListPage",
  components: {
    IonContent,
    IonHeader,
    IonButtons,
    IonIcon,
    IonToolbar,
    IonPage,
    IonRow,
    IonCol,
    IonAvatar,
  },
  data() {
    return {
      tempRewards: [],
      rewardsList: [],
    };
  },
  methods: {
    async getRewards() {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = BASE_URI + "/rewards/get-rewards";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.tempRewards = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.tempRewards.length; i++) {
        let paramsData = {
          rewardsId: this.tempRewards[i].uuid,
          userId: localStorage.getItem("id"),
        };

        let axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        };

        let url = BASE_URI + "/rewards/validate-redemption";

        let result = await axios
          .post(url, paramsData, axiosConfig)
          .then((res) => res.data)
          .catch((err) => {
            throw err.response.status;
          });

        let validate = result && result.data.length ? "redeemed" : "active";

        if (validate == "active") {
          this.rewardsList.push(this.tempRewards[i]);
        }
      }
    },
  },
  async created() {
    await this.getRewards();
  },
});
</script>
<style scoped>
ion-toolbar {
  --background: #ffffff;
  --border-color: #f3f3f3;
  --border-width: 1px 0;
}
.rewardlist {
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}
.back-icon {
  height: 20px;
  width: 20px;
  margin-top: 35%;
  margin-left: 10%;
}
.h1-menu {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 42px;
  margin-bottom: 25px;
  text-align: center;
}
.reward-icon {
  width: 46px;
  height: 46px;
  margin-left: 10px;
  margin-right: 10px;
}
.reward-col2 {
  margin-left: 20px;
}
.reward-resto {
  font-style: normal;
  font-weight: 400;
  font-family: "Gilroy";
  line-height: 135%;
  font-size: 12px;
  margin-left: 0px;
  margin-top: 15px;
}
.reward-expiry {
  font-style: normal;
  font-weight: 400;
  font-family: "Gilroy";
  line-height: 135%;
  font-size: 12px;
  margin-left: 0px;
  padding-bottom: 15px;
}
.reward-txtlight {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  color: #828282;
}
.reward-txtbold {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
  text-transform: uppercase;
}
.promo-count {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  margin-top: 12px;
  float: right;
}
.redeem-row {
  height: 50px;
}
.redeem-col {
  padding-top: 5px;
  padding-left: -10px;
}
.redeem-col2 {
  padding-top: 10px;
}
.payment-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
  margin-left: 5px;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 14px;
}
.input-code {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  border-bottom: #ebebeb 1px solid;
}
.select-fr {
  float: right;
}
.h1-sub {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.h1-sub2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 15px;
}
ion-button {
  --background: #ff8000;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #ffffff;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 35px;
  text-transform: none;
  --box-shadow: none;
}
.center-img {
  text-align: center;
  display: block !important;
}
.no-result {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  margin-top: 0px;
  margin-bottom: 5px;
  display: block;
}
</style>
