<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="container-cart">
        <a @click="$router.go(-1)">
          <ion-icon class="back-icon" src="assets/icon/arrow-left.svg" />
        </a>
        <span class="h1-cart">Checkout</span>
      </div>
      <ion-row class="heading-row-list">
        <h1 class="heading-category">Order Summary</h1>
      </ion-row>
      <div class="orderlist-div" v-for="orders in cartOrders" :key="orders.id">
        <ion-row class="row-orderlist">
          <ion-col size="1">
            <span class="product-cart-quantity">{{ orders.quantity }} x</span>
          </ion-col>
          <ion-col>
            <span class="product-cart-name">
              {{ orders.product.productName }}
            </span>
            <div v-for="variations in orders.variations" :key="variations.id">
              <span class="product-cart-addon">
                {{ variations.name }}
              </span>
            </div>
            <a :href="'/cafemenu?id=' + orders.cafe.cafeId">
              <span class="product-cart-edit"> Edit </span>
            </a>
          </ion-col>
          <ion-col>
            <span class="product-cart-price">
              $ {{ orders.pricePerOrder.toFixed(2) }}
            </span>
          </ion-col>
          <ion-col size="1">
            <a @click="presentAlert(orders.uuid)">
              <img
                src="assets/icon/trash.png"
                class="trash-icon"
                color="primary"
              />
            </a>
          </ion-col>
        </ion-row>
      </div>

      <ion-row class="heading-row-list"
        ><h1 class="heading-category">Pickup</h1></ion-row
      >
      <ion-row class="checkout-row">
        <ion-col>
          <OrderTime name="OrderTime" />
        </ion-col>
      </ion-row>

      <ion-row class="payment-row">
        <ion-col size="6" class="payment-col">
          <ion-icon src="assets/icon/card.svg" class="banner-icon"></ion-icon>
          <span class="payment-label">Payment Method</span>
        </ion-col>
        <ion-col size="6" v-if="isStripeCheckout">
          <img src="assets/icon/stripe.jpg" class="banner-icon2" />
        </ion-col>
        <ion-col size="6" v-else>
          <img
            src="assets/icon/ticket-discount.svg"
            class="banner-icon2"
            style="height: 30px"
          />
        </ion-col>
      </ion-row>
      <RewardsComponent
        name="RewardsComponent"
        @rewards-type="rewardsType($event)"
      />
    </ion-content>
    <ion-row class="row-sub">
      <ion-col>
        <span class="product-sublabel">
          SubTotal ({{ totalCartQty }} item/s)
        </span>
      </ion-col>
      <ion-col>
        <span class="product-subtotal">
          $ {{ subTotalCartPrice.toFixed(2) }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row class="row-sub" v-if="discountPrice > 0">
      <ion-col><span class="product-sublabel">Discount</span> </ion-col>
      <ion-col>
        <span class="product-subtotal">
          - $ {{ discountPrice.toFixed(2) }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row class="row-sub">
      <ion-col><span class="product-sublabel">Service Fee</span> </ion-col>
      <ion-col>
        <span class="product-subtotal"> $ {{ serviceFee.toFixed(2) }} </span>
      </ion-col>
    </ion-row>
    <ion-row class="row-total">
      <ion-col><span class="product-total-label">Total</span> </ion-col>
      <ion-col>
        <span class="product-cart-price2">
          $ {{ totalCartPrice.toFixed(2) }}
        </span>
      </ion-col>
    </ion-row>
    <ion-button
      expand="block"
      color="primary"
      class="carttext-btn"
      @click="placeOrder"
      v-if="!isStripeCheckout"
    >
      Place Order
    </ion-button>
    <ion-button
      expand="block"
      color="primary"
      class="carttext-btn"
      @click="placeStripeOrder"
      v-else
    >
      Place Orders
    </ion-button>
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import OrderTime from "@/components/OrderTime.vue";
import RewardsComponent from "@/views/order/RewardsComponent.vue";

import {
  IonRow,
  IonCol,
  IonModal,
  IonButton,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonDatetimeButton,
  IonPage,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  alertController,
} from "@ionic/vue";
import { loadStripe } from "@stripe/stripe-js";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

const PUBLISHABLE_KEY =
  "pk_live_51MmPXnJiULxP1k5TKPE8uNWJwQzwELMXvU4sw3tSi9MLu9zrGaWcqcVJfCRPuZPgZutzkfA7e2zJD40FZ209irff00L0H82ui4";

export default defineComponent({
  name: "CheckoutPage",
  components: {
    OrderTime,
    IonRow,
    IonCol,
    IonModal,
    IonButton,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonDatetimeButton,
    IonPage,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    RewardsComponent,
  },
  data() {
    return {
      cartOrders: [],
      cartOrderIds: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      serviceFee: 0,
      currentCartPrice: 0,
      totalCartPrice: 0,
      promptMessage: "",
      cartStatus: "",
      tempRewards: [],
      rewardsList: [],
      lowestCartPrice: 0,
      discountPrice: 0,
      rewardsId: "",
      isStripeCheckout: true,
    };
  },
  methods: {
    async rewardsType(value) {
      this.rewardsId = value ? value.uuid : "";

      this.serviceFee = this.totalCartQty * 0.5;
      this.totalCartPrice = this.currentCartPrice;

      if (value !== null && value.type.toLowerCase() == "free_regular") {
        this.isStripeCheckout = this.totalCartQty == 1 ? false : true;
        if (this.cartOrderIds.length == 1) {
          this.discountPrice = this.lowestCartPrice / this.totalCartQty;
          this.serviceFee = this.serviceFee - 0.5;
          this.totalCartPrice =
            this.totalCartPrice - (this.discountPrice + 0.5);
        } else {
          this.discountPrice = this.lowestCartPrice;
          this.serviceFee = this.serviceFee - 0.5;
          this.totalCartPrice =
            this.totalCartPrice - (this.discountPrice + 0.5);
        }
      } else if (value !== null && value.type.toLowerCase() == "off_price_2") {
        this.discountPrice = 2;
        this.totalCartPrice = this.totalCartPrice - this.discountPrice;
      } else if (
        value !== null &&
        value.type.toLowerCase() == "off_percent_20"
      ) {
        this.discountPrice = this.subTotalCartPrice * 0.2;
        this.totalCartPrice = this.totalCartPrice - this.discountPrice;
      } else {
        this.discountPrice = 0;
        this.totalCartPrice = this.currentCartPrice;
      }
    },
    async presentAlert(id) {
      if (id) {
        const alert = await alertController.create({
          subHeader: "Are you sure you want to delete this item?",
          buttons: [
            {
              text: "No",
              role: "cancel",
              handler: () => {},
            },
            {
              text: "Yes",
              role: "confirm",
              handler: () => {
                this.removeCartOrder(id);
              },
            },
          ],
        });

        await alert.present();
        await alert.onDidDismiss();
      } else {
        const alert = await alertController.create({
          message: this.promptMessage,
        });

        await alert.present();
        await alert.onDidDismiss();

        if (this.totalCartQty == 1) window.location.href = "/cart";
        else if (this.cartStatus == 400) window.location.href = "/cart";
        else window.location.reload();
      }
    },
    async getCartOrders(userId, cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/cart/get-cart-orders/?userId=" +
          userId +
          "&status=pending" +
          "&cafeId=" +
          cafeId
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "No cart order/s available.";
          this.cartStatus = err.response.status;
          this.presentAlert();
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];
      const cartOrderPrices = [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.cartOrderIds.push(this.cartOrders[i].id);
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;

        cartOrderPrices.push(this.cartOrders[i].pricePerOrder);
      }

      this.lowestCartPrice = Math.min.apply(Math, cartOrderPrices);
      this.serviceFee = this.totalCartQty * 0.5;
      this.currentCartPrice = this.subTotalCartPrice + this.serviceFee;
      this.totalCartPrice = this.subTotalCartPrice + this.serviceFee;
    },
    async removeCartOrder(cartOrderId) {
      let headers = {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      };

      let paramsData = {
        id: cartOrderId,
      };

      await axios
        .delete(BASE_URI + "/cart/delete-cart-order", {
          headers,
          data: paramsData,
        })
        .then((res) => {
          this.promptMessage = res.data.message;
          this.presentAlert();
        })
        .catch((err) => {
          throw err.response.status;
        });
    },
    async placeOrder() {
      let paramsAddData = {
        cartOrderIds: this.cartOrderIds,
        orderQty: this.totalCartQty,
        subTotal: this.subTotalCartPrice,
        serviceFee: this.serviceFee.toFixed(2),
        total: this.totalCartPrice.toFixed(2),
        cafeId: this.cartOrders[0].cafe.cafeId,
        discount: this.discountPrice,
        rewardsId: this.rewardsId,
        userId: localStorage.getItem("id"),
      };

      let paramsUpdateData = {
        userId: localStorage.getItem("id"),
        cafeId: this.cartOrders[0].cafe.cafeId,
        status: "order_placed",
      };

      let redeemRewardsData = {
        rewardsId: this.rewardsId,
      };

      localStorage.setItem("paramsAddData", JSON.stringify(paramsAddData));
      localStorage.setItem(
        "paramsUpdateData",
        JSON.stringify(paramsUpdateData)
      );
      localStorage.setItem(
        "redeemRewardsData",
        JSON.stringify(redeemRewardsData)
      );

      window.location.href =
        window.location.origin +
        "/orderstatus?cafeId=" +
        this.cartOrders[0].cafe.cafeId;
    },
    async placeStripeOrder() {
      let stripe = await loadStripe(PUBLISHABLE_KEY);

      let paramsAddData = {
        cartOrderIds: this.cartOrderIds,
        orderQty: this.totalCartQty,
        subTotal: this.subTotalCartPrice,
        serviceFee: this.serviceFee.toFixed(2),
        total: this.totalCartPrice.toFixed(2),
        cafeId: this.cartOrders[0].cafe.cafeId,
        discount: this.discountPrice,
        rewardsId: this.rewardsId,
        userId: localStorage.getItem("id"),
      };

      let paramsUpdateData = {
        userId: localStorage.getItem("id"),
        cafeId: this.cartOrders[0].cafe.cafeId,
        status: "order_placed",
      };

      let redeemRewardsData = {
        rewardsId: this.rewardsId,
      };

      let paramsCheckoutData = JSON.stringify({
        product: {
          name: this.cartOrders[0].cafe.shopName,
          price: this.totalCartPrice,
          quantity: 1,
        },
        routes: {
          success_url:
            window.location.origin +
            "/orderstatus?cafeId=" +
            this.cartOrders[0].cafe.cafeId,
          cancel_url: window.location.origin + "/cart",
        },
      });

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      await axios
        .post(BASE_URI + "/payments/checkout", paramsCheckoutData, axiosConfig)
        .then(async (response) => {
          localStorage.setItem("paramsAddData", JSON.stringify(paramsAddData));
          localStorage.setItem(
            "paramsUpdateData",
            JSON.stringify(paramsUpdateData)
          );
          localStorage.setItem(
            "redeemRewardsData",
            JSON.stringify(redeemRewardsData)
          );

          return await response.data.data;
        })
        .then((session) => {
          return stripe.redirectToCheckout({ sessionId: session.id });
        })
        .catch((error) => {
          throw error;
        });
    },
    async getRewardsByCafeId(cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = BASE_URI + "/rewards/get-rewards-by-cafe-id?cafeId=" + cafeId;

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.tempRewards = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.tempRewards.length; i++) {
        let paramsData = {
          rewardsId: this.tempRewards[i].uuid,
          userId: localStorage.getItem("id"),
        };

        let axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        };

        let url = BASE_URI + "/rewards/validate-redemption";

        let result = await axios
          .post(url, paramsData, axiosConfig)
          .then((res) => res.data)
          .catch((err) => {
            throw err.response.status;
          });

        let validate = result && result.data.length ? "redeemed" : "active";

        if (validate == "active") {
          this.rewardsList.push(this.tempRewards[i]);
        }
      }
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cafeId = urlParams.get("cafeId") || "";
    const userId = localStorage.getItem("id") || null;

    if (cafeId == "") window.location.href = "/cart";
    else await this.getCartOrders(userId, cafeId);

    this.getRewardsByCafeId(cafeId);
  },
});
</script>
<style scoped>
.container-cart {
  padding-top: 10%;
  padding-bottom: 5%;
  padding-left: 8%;
}
.center {
  margin: auto;
}
.back-icon {
  height: 20px;
  width: 20px;
}
.h1-cart {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #1b1b1b;
  margin-left: 30%;
  top: 5px;
}
.heading-row-list {
  background-color: #f9f9f9;
  padding-left: 5%;
  border-bottom: 1px solid #f3f3f3;
}
.heading-category {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #1b1b1b;
  margin-top: 8%;
  margin-bottom: 15px;
}
.row-cart {
  padding: 10%;
}
.row-sub {
  padding-top: 0%;
  padding-left: 5%;
  padding-right: 0%;
}
.row-orderlist {
  padding-top: 0%;
  padding-left: 3%;
  padding-right: 0%;
}
.row-total {
  padding-top: 3%;
  padding-left: 2%;
  padding-right: 5%;
  margin-top: 3%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
}
.orderlist-div {
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-cart-quantity {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
}
.product-cart-name {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding: 0%;
}
.product-cart-addon {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  display: block;
  padding: 2%;
}
.product-total-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
  display: block;
  padding: 2%;
}
.product-cart-edit {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #ff8000;
  display: block;
  padding: 2%;
}
.product-cart-price {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: 16px;
}
.product-cart-price2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: -5px;
}
.product-subtotal {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: 16px;
}
.product-sublabel {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 52px;
  text-transform: none;
  --box-shadow: none;
}
ion-button:active {
  --color: #ff8000;
}
.carttext-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
ion-select {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  --placeholder-color: #ffc34d;
  --placeholder-opacity: 1;
}
ion-select::part(icon) {
  color: #ffffff;
  opacity: 0;
}
ion-select-option {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #1b1b1b;
}
.select-fr {
  float: right;
}
.checkout-row {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 5%;
  padding-bottom: 5%;
}
.payment-row {
  border-bottom: 1px solid #f3f3f3;
}
.payment-col {
  padding-top: 5%;
  padding-left: 5%;
}
.pickup-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #1b1b1b;
}
.payment-option {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 14px;
}
.banner-icon2 {
  vertical-align: middle;
  padding-right: 5px;
  width: 80px;
  float: right;
}
.trash-icon {
  width: 16px;
}
.payment-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
  margin-left: 5px;
}
.payment-option {
  display: block;
}
.input-code {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  border-bottom: #ebebeb 1px solid;
  text-align: right;
  text-transform: uppercase;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  flex: 1;
  padding: 0 0.5em;
  color: #ff8000;
  cursor: pointer;
  font-size: 14px;
  font-family: "Gilroy";
  padding-right: 20px;
  text-align: right;
}
select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  line-height: 3;
  background: #fff;
  overflow: hidden;
  border-radius: 0.25em;
}
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #2b2e2e;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
.select:hover::after {
  color: #23b499;
}
</style>
