<template>
  <div>
    <ion-list :inset="true" class="reward-list">
      <ion-item :button="true" :detail="false" id="select-reward">
        <ion-icon src="assets/icon/ticket.svg" class="banner-icon"></ion-icon>
        <ion-label class="reward-label">Choose Reward</ion-label>
        <div id="selected-rewards" class="reward-input">
          {{ selectedRewardText }}
        </div>
      </ion-item>
    </ion-list>

    <ion-modal trigger="select-reward" ref="modal">
      <RewardsModal
        class="ion-page"
        title="Choose Reward"
        :items="rewardsList"
        :selectedItems="selectedRewards"
        @selection-change="rewardSelectionChanged($event)"
        @selection-cancel="$refs.modal.$el.dismiss()"
      ></RewardsModal>
    </ion-modal>
  </div>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonIcon,
} from "@ionic/vue";
import axios from "axios";
import { defineComponent } from "vue";
import RewardsModal from "@/views/order/RewardsModal.vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  components: {
    RewardsModal,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonIcon,
  },
  data() {
    return {
      tempRewards: [],
      rewardsList: [],
      modal: null,
      selectedRewardText: "None",
      selectedRewards: [],
    };
  },
  methods: {
    formatData(data) {
      if (data.length === 1) {
        const reward = this.rewardsList.find(
          (reward) => reward.title === data[0]
        );
        this.$emit("rewards-type", reward);
        return reward.title;
      } else {
        this.$emit("rewards-type", null);
        return `${data.length} items`;
      }
    },

    rewardSelectionChanged(rewards) {
      this.selectedRewards = rewards;
      this.selectedRewardText = this.formatData(this.selectedRewards);
      this.$refs.modal.$el.dismiss();
    },

    async getRewardsByCafeId(cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = BASE_URI + "/rewards/get-rewards-by-cafe-id?cafeId=" + cafeId;

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.tempRewards = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.tempRewards.length; i++) {
        let paramsData = {
          rewardsId: this.tempRewards[i].uuid,
          userId: localStorage.getItem("id"),
        };

        let axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        };

        let url = BASE_URI + "/rewards/validate-redemption";

        let result = await axios
          .post(url, paramsData, axiosConfig)
          .then((res) => res.data)
          .catch((err) => {
            throw err.response.status;
          });

        let validate = result && result.data.length ? "redeemed" : "active";

        if (validate == "active") {
          this.rewardsList.push(this.tempRewards[i]);
        }
      }
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cafeId = urlParams.get("cafeId") || "";

    this.getRewardsByCafeId(cafeId);
  },
});
</script>
<style scoped>
.reward-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
  margin-left: 5px;
}
.reward-input {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  border-bottom: #ebebeb 1px solid;
  float: right;
  text-transform: uppercase;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 14px;
}
#select-reward {
  padding-left: 0px;
}
.reward-list {
  margin-inline-start: 0px;
  margin-inline-end: 22px;
}
</style>
