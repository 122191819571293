<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="container-support">
        <ion-row>
          <ion-col size="4">
            <router-link to="/" replace
              ><ion-icon class="back-icon" src="assets/icon/arrow-left.svg"
            /></router-link>
          </ion-col>
          <ion-col size="8">
            <span class="h1-od">Support center</span>
          </ion-col>
        </ion-row>
        <ion-searchbar
          class="support-search"
          placeholder="Find a solution"
        ></ion-searchbar>
      </div>
      <ion-row router-link="/support-list" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Guidelines</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-list" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Report problems</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-list" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Feedbacks</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <ion-row router-link="/support-list" class="support-row">
        <ion-col size="9">
          <span class="support-txt">Term and conditions</span>
        </ion-col>
        <ion-col class="arrowright-icon position-right">
          <img src="assets/icon/arrow-right.png" />
        </ion-col>
      </ion-row>
      <hr class="support-row" />
      <div class="center-img">
        <img src="assets/images/notification-empty.png" />
      </div>
      <span class="call-txt">Call us: 123 456 789</span>
      <!-- content end -->
      <router-link to="/support"
        ><ion-button expand="block" color="primary" class="carttext-btn"
          >Send us an email</ion-button
        ></router-link
      >
    </ion-content>
  </ion-page>
</template>
<style scoped>
.container-support {
  padding-top: 10%;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
}
.back-icon {
  height: 20px;
  width: 20px;
}
.h1-od {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #1b1b1b;
}
.support-search {
  --background: #f3f3f3;
  --border-radius: 2px;
  --icon-color: #1b1b1b;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.support-row {
  border-top: 1px solid #f3f3f3;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}
.center-img {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 52px;
  text-transform: none;
}
ion-button:active {
  --color: #ff8000;
}
.carttext-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.support-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding-top: 5px;
}
.arrowright-icon {
  text-align: right;
  padding-top: 8px;
}
.position-right {
  position: absolute;
  right: 20px;
}
.call-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #828282;
  display: block;
  margin-top: 40px;
  margin-bottom: 15px;
}
</style>
<script>
import { defineComponent } from "vue";
import {
  IonRow,
  IonCol,
  IonButton,
  IonPage,
  IonContent,
  IonIcon,
  IonSearchbar,
} from "@ionic/vue";

export default defineComponent({
  name: "SupportHomePage",
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonPage,
    IonContent,
    IonIcon,
    IonSearchbar,
  },
});
</script>
