<template>
  <div>
    <ion-header>
      <ion-toolbar class="rewards-header">
        <ion-buttons slot="start">
          <ion-button @click="cancelChanges()" class="link-rewards"
            >Cancel</ion-button
          >
        </ion-buttons>
        <ion-title class="h1-rewards">{{ $props.title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="confirmChanges()" class="link-rewards"
            >Done</ion-button
          >
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          @ionInput="searchbarInput($event)"
          class="search-rewards"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-list id="modal-list" class="no-margin" :inset="true">
        <ion-item v-for="item in filteredItems" :key="item.id">
          <ion-checkbox
            :value="item.title"
            :checked="isChecked(item.title)"
            @ionChange="radioboxChange($event)"
            class="radio-rewards"
            :disabled="
              workingSelectedValues.length >= 1 &&
              workingSelectedValues.indexOf(item.title) == -1
            "
          ></ion-checkbox>
          <span class="h1-list">
            <span style="text-transform: uppercase">{{ item.title }}</span
            ><br />
            <span class="h2-list">{{ item.description }}</span>
          </span>
        </ion-item>
        <div v-if="filteredItems.length <= 0" style="text-align: center">
          No results found.
        </div>
      </ion-list>
      <hr class="hr" />
    </ion-content>
  </div>
</template>

<script>
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    items: Array,
    selectedItems: Array,
    title: {
      type: String,
      default: "Select Items",
    },
  },
  emits: ["selection-cancel", "selection-change"],
  components: {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonRadioGroup,
    IonRadio,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonTitle,
    IonSearchbar,
    IonToolbar,
  },
  setup(props, { emit }) {
    const filteredItems = ref([...props.items]);
    const workingSelectedValues = ref([...props.selectedItems]);

    const isChecked = (value) => {
      return (
        workingSelectedValues.value.find((item) => item === value) !== undefined
      );
    };

    const cancelChanges = () => {
      emit("selection-cancel");
    };

    const confirmChanges = () => {
      emit("selection-change", workingSelectedValues.value);
    };

    const searchbarInput = (ev) => {
      filterList(ev.target.value);
    };

    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */

    const filterList = (searchQuery) => {
      /**
       * If no search query is defined,
       * return all options.
       */
      if (searchQuery === undefined) {
        filteredItems.value = [...props.items];
      } else {
        /**
         * Otherwise, normalize the search
         * query and check to see which items
         * contain the search query as a substring.
         */
        const normalizedQuery = searchQuery.toLowerCase();
        filteredItems.value = props.items.filter((item) => {
          return item.description.toLowerCase().includes(normalizedQuery);
        });
      }
    };

    const radioboxChange = (ev) => {
      const { checked, value } = ev.detail;

      if (checked) {
        workingSelectedValues.value = [...workingSelectedValues.value, value];
      } else {
        workingSelectedValues.value = workingSelectedValues.value.filter(
          (item) => item !== value
        );
      }
    };
    return {
      filteredItems,
      workingSelectedValues,
      isChecked,
      cancelChanges,
      confirmChanges,
      searchbarInput,
      radioboxChange,
    };
  },
});
</script>
<style scoped>
.rewards-header {
  height: 55px;
}
.h1-rewards {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #1b1b1b;
  padding: 35px 0px 20px 0px;
}
.link-rewards {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #ff8000;
  margin-top: 16px;
}
.search-rewards {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #1b1b1b;
  padding: 15px 0px 20px 0px;
}
.h1-list {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1b1b1b;
  padding: 20px 0px 10px 15px;
  display: block;
}
.h2-list {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: lighter;
  font-size: 12px;
  line-height: 120%;
  color: #1b1b1b;
  margin: 5px 0px 10px 0px;
  display: block;
}
.no-margin {
  margin: 0px;
}
.radio-rewards-test {
  border: #828282 1px solid;
  height: 25px;
  width: 25px;
  padding-bottom: 3px;
}
.hr {
  background-color: #f9f9f9;
  margin-top: 10px;
  border-bottom: 1px solid #c8c7cc;
  width: 100%;
}
</style>
