<template>
  <ion-footer>
    <ion-toolbar>
      <ion-row>
        <ion-col class="footer-col">
          <a href="/home"
            ><img class="footer-icon" src="assets/icon/footer-home.png"
          /></a>
        </ion-col>
        <ion-col class="footer-col">
          <a href="/search"
            ><img class="footer-icon" src="assets/icon/footer-search.png" />
          </a>
        </ion-col>
        <ion-col class="footer-col">
          <a href="/cart"
            ><img class="footer-icon" src="assets/icon/footer-cart.png" />
          </a>
          <ion-badge color="danger" class="home-notif-margin">
            {{ cartOrders.length }}
          </ion-badge>
        </ion-col>
        <ion-col class="footer-col">
          <a href="/favorites"
            ><img class="footer-icon" src="assets/icon/footer-favorite.png" />
          </a>
        </ion-col>
        <ion-col class="footer-col">
          <a href="/track"
            ><img class="footer-icon" src="assets/icon/track.png" />
          </a>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</template>
<script>
import { defineComponent } from "vue";
import { IonFooter, IonToolbar, IonRow, IonCol, IonBadge } from "@ionic/vue";
import axios from "axios";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "TheFooter",
  components: { IonFooter, IonToolbar, IonRow, IonCol, IonBadge },
  data() {
    return {
      cartOrders: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      serviceFee: 0,
      totalCartPrice: 0,
      promptMessage: "",
      fullName:
        localStorage.getItem("name") !== undefined
          ? localStorage.getItem("name")
          : "John Doe",
    };
  },
  methods: {
    async getCartOrders(userId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/cart/get-cart-orders/?userId=" +
          userId +
          "&status=pending" +
          "&groupByCafeId=true"
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;
      }

      this.serviceFee = this.totalCartQty * 0.5;
      this.totalCartPrice = this.subTotalCartPrice + this.serviceFee;
    },
  },
  async created() {
    const userId = localStorage.getItem("id") || null;
    await this.getCartOrders(userId);
  },
});
</script>
<style scoped>
ion-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  min-height: 50px; /* set the minimum height you want */
  width: 100%;
  background-color: #fff; /* set the background color you want */
  z-index: 999; /* set the z-index to ensure it's on top of other content */
  border-top: 1px solid #f3f3f3;
}
.footer-icon {
  padding: 10px 20px 10px 25px;
}
.home-notif-margin {
  margin-left: -24px;
  position: absolute;
}
</style>
