<template>
  <ion-page>
    <AdminHeaderMenu name="AdminHeaderMenu" />
    <ion-content>
      <h1 class="h1-ordercard page-title">Order History</h1>
      <hr class="solid" />
      <ion-searchbar
        class="header-search"
        placeholder="Find an Order"
        type="text"
        v-model="searchOrder"
      >
      </ion-searchbar>
      <div v-if="filterSearch.length">
        <ion-card
          class="order-card"
          v-for="order in filterSearch"
          :key="order.id"
        >
          <ion-card-header class="nopad-bottom">
            <ion-row>
              <ion-col class="h1-ordercard">
                {{ order.customerInfo.fullName }}
              </ion-col>
              <ion-col size="3">
                <ion-icon
                  src="assets/icon/checkmark-outline.svg"
                  class="ion-float-right card-icon"
                />
              </ion-col>
            </ion-row>
            <ion-card-subtitle class="h2-ordercard">
              Order #
              {{ order.uuid.replace(/-/gi, "").toUpperCase().substring(0, 6) }}
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content class="nopad-left">
            <ion-list>
              <ion-item>
                <ion-icon
                  src="assets/icon/time-outline.svg"
                  class="banner-icon"
                >
                </ion-icon>
                <span class="list-ordercard">{{ order.dateTimeOrdered }}</span>
              </ion-item>

              <ion-item>
                <ion-icon
                  src="assets/icon/cart-outline.svg"
                  class="banner-icon"
                >
                </ion-icon>
                <span class="list-ordercard">
                  {{ order.quantity }} item/s
                </span>
              </ion-item>
            </ion-list>
            <a
              :href="
                '/admin/order-card-details?cartOrderIds=' +
                order.cartOrderIds +
                '&orderId=' +
                order.uuid
              "
              class="link-banner ion-margin-bottom"
            >
              Check Order Details
            </a>
          </ion-card-content>
        </ion-card>
      </div>
      <div v-else>
        <ion-card>
          <ion-card-content style="text-align: center">
            No order/s found.
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import AdminHeaderMenu from "@/components/admin/AdminHeaderMenu.vue";

import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonHeader,
  IonItem,
  IonButtons,
  IonMenuButton,
  IonList,
  IonIcon,
  IonToolbar,
  IonMenu,
  IonSearchbar,
  IonCol,
  IonRow,
  alertController,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "AdminOrderHistory",
  components: {
    AdminHeaderMenu,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonPage,
    IonContent,
    IonHeader,
    IonItem,
    IonButtons,
    IonMenuButton,
    IonList,
    IonIcon,
    IonToolbar,
    IonMenu,
    IonSearchbar,
    IonCol,
    IonRow,
  },
  data() {
    return {
      orders: [],
      promptMessage: "",
      searchOrder: "",
    };
  },
  computed: {
    filterSearch() {
      return this.orders.filter((order) => {
        return (
          !this.searchOrder ||
          order.customerInfo.fullName
            .toLowerCase()
            .indexOf(this.searchOrder.toLowerCase()) > -1 ||
          order.uuid
            .toLowerCase()
            .indexOf(this.searchOrder.toLocaleLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    async presentAlert(status) {
      const alert = await alertController.create({
        message: this.promptMessage,
      });

      await alert.present();
      await alert.onDidDismiss();
      if (status == 200 || status === 400) window.location.href = "/admin";
      else return;
    },
    async getAdminOrders(cafeId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url =
        BASE_URI +
        "/orders/admin/get-orders/?cafeId=" +
        cafeId +
        "&status=collected";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];
    },
  },
  async created() {
    await this.getAdminOrders(localStorage.getItem("cafeId"));
  },
});
</script>
<style scoped>
.order-card {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
}
.h1-ordercard {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b1b;
  padding-left: 0px;
}
.h2-ordercard {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  text-transform: none;
}
.list-ordercard {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
}
.nopad-bottom {
  padding-bottom: 0px !important;
}
.nopad-left {
  padding-left: 0px !important;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 15px;
  height: 45px;
  text-transform: none;
  --box-shadow: none;
}
ion-button:active {
  --color: #0057ff;
}
.link-banner {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #ff8000;
  float: left;
  padding-left: 20px;
  padding-top: 20px;
}
a.link-banner {
  text-decoration: none;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 15px;
  width: 11px;
}
.card-icon {
  vertical-align: middle;
  width: 25px;
  height: 25px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 6px;
  height: 6px;
  padding-right: 10px;
  padding-left: 10px;
}
ion-searchbar.header-search {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  --border-radius: 0px;
  padding-bottom: 0px;
}
ion-searchbar.ios.header-search {
  --cancel-button-color: #1b1b1b;
}
ion-searchbar.md.header-search {
  --cancel-button-color: #1b1b1b;
}
.page-title {
  margin-left: 20px;
}
hr.solid {
  border-top: 1px solid #f3f3f3;
  margin-bottom: 10px;
}
</style>
