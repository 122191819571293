<template>
  <div>
    <p>Current Time: {{ currentTime }}</p>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "MyComponent",
  data() {
    return {
      currentTime: "",
    };
  },
  mounted() {
    this.currentTime = moment().format("hh:mm:ss A");
  },
};
</script>
