<template>
  <ion-page style="margin-bottom: 20px">
    <!--content start -->
    <ion-content>
      <div class="container-cart">
        <a @click="$router.go(-1)">
          <ion-icon class="back-icon" src="assets/icon/arrow-left.svg" />
        </a>
        <span class="h1-cart">
          Order #
          {{ orderId.replace(/-/gi, "").toUpperCase().substring(0, 6) }}
        </span>
      </div>
      <ion-row class="heading-row-list">
        <h1 class="heading-category">{{ customerName }}</h1>
      </ion-row>
      <div
        class="orderlist-div"
        v-for="cartOrder in cartOrders"
        :key="cartOrder.id"
      >
        <ion-row class="row-orderlist">
          <ion-col size="1">
            <span class="product-cart-quantity">
              {{ cartOrder.quantity }} x
            </span>
          </ion-col>
          <ion-col>
            <span class="product-cart-name">
              {{ cartOrder.product.productName }}
            </span>
            <div
              v-for="cartOrderVariations in cartOrder.variations"
              :key="cartOrderVariations.id"
            >
              <span class="product-cart-addon">
                {{ cartOrderVariations.name }}
              </span>
            </div>
          </ion-col>
          <ion-col>
            <span class="product-cart-price">
              $ {{ cartOrder.pricePerOrder.toFixed(2) }}
            </span>
          </ion-col>
        </ion-row>
      </div>

      <ion-row class="payment-row">
        <ion-col size="6" class="payment-col">
          <span class="payment-label">Order Date & Time</span>
        </ion-col>
        <ion-col size="6" class="payment-col">
          <span class="payment-label2">{{ dateTimeOrdered }}</span>
        </ion-col>
      </ion-row>

      <ion-row class="payment-row">
        <ion-col size="6" class="payment-col">
          <span class="payment-label">Order Status</span>
        </ion-col>
        <ion-col size="6" class="payment-col">
          <span class="payment-label2">{{ orderStatus.toUpperCase() }}</span>
        </ion-col>
      </ion-row>

      <ion-row class="payment-row">
        <ion-col size="6" class="payment-col">
          <span class="payment-label">Pickup Date & Time</span>
        </ion-col>
        <ion-col size="6" class="payment-col">
          <span class="payment-label2">{{ dateTimeForPickup }}</span>
        </ion-col>
      </ion-row>

      <ion-row class="payment-row">
        <ion-col size="6" class="payment-col">
          <ion-icon src="assets/icon/card.svg" class="banner-icon"></ion-icon>
          <span class="payment-label">Payment Method</span>
        </ion-col>
        <ion-col size="6" class="payment-col">
          <img src="assets/icon/stripe.jpg" class="banner-icon2" />
        </ion-col>
      </ion-row>

      <!-- content end -->
    </ion-content>
    <ion-row class="row-sub">
      <ion-col>
        <span class="product-sublabel">
          SubTotal: {{ totalCartQty }} item/s
        </span>
      </ion-col>
      <ion-col>
        <span class="product-subtotal">
          $ {{ subTotalCartPrice.toFixed(2) }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row class="row-sub">
      <ion-col><span class="product-sublabel">Discount</span> </ion-col>
      <ion-col>
        <span class="product-subtotal">
          - $ {{ discountPrice.toFixed(2) }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row class="row-sub">
      <ion-col><span class="product-sublabel">Service Fee</span> </ion-col>
      <ion-col>
        <span class="product-subtotal"> $ {{ serviceFee.toFixed(2) }} </span>
      </ion-col>
    </ion-row>
    <ion-row class="row-total">
      <ion-col><span class="product-total-label">Total</span> </ion-col>
      <ion-col>
        <span class="product-cart-price2">
          $ {{ totalCartPrice.toFixed(2) }}
        </span>
      </ion-col>
    </ion-row>
    <div v-if="actionBtn == 'confirm'">
      <ion-button
        expand="block"
        color="primary"
        class="carttext-btn"
        @click="confirmOrder(this.orderId)"
      >
        Confirm Order
      </ion-button>
    </div>
    <div v-if="actionBtn == 'finish'">
      <ion-button
        expand="block"
        color="primary"
        class="carttext-btn"
        @click="
          confirmAlert(
            this.orderId,
            this.orders[0].cafe.cafeId,
            this.orders[0].customerInfo.userId
          )
        "
      >
        Finish Order
      </ion-button>
    </div>
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import {
  IonRow,
  IonCol,
  IonModal,
  IonButton,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonDatetimeButton,
  IonPage,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  alertController,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "OrderCardDetails",
  components: {
    IonRow,
    IonCol,
    IonModal,
    IonButton,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonDatetimeButton,
    IonPage,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
  },
  data() {
    return {
      cartOrders: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      serviceFee: 0,
      totalCartPrice: 0,
      cafeId: "",
      cafeName: "",
      cafeAddress: "",
      orders: [],
      orderId: "",
      actionBtn: "",
      dateTimeOrdered: "",
      orderStatus: "",
      dateTimeForPickup: "",
      customerName: "",
      promptMessage: "",
      discountPrice: 0,
    };
  },
  methods: {
    async presentAlert(status) {
      const alert = await alertController.create({
        message: this.promptMessage,
      });

      await alert.present();
      await alert.onDidDismiss();
      if (status == 200 || status === 400) window.location.href = "/admin";
      else return;
    },
    async confirmAlert(orderId, cafeId, userId) {
      const alert = await alertController.create({
        subHeader:
          "Are you sure you want to finish this order? It will be moved to order history right after.",
        buttons: [
          {
            text: "No",
            role: "cancel",
            handler: () => {},
          },
          {
            text: "Yes",
            role: "confirm",
            handler: () => {
              this.finishOrder(orderId, cafeId, userId);
            },
          },
        ],
      });

      await alert.present();
      await alert.onDidDismiss();
    },
    async getCartOrders(cartOrderIds) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url =
        BASE_URI + "/cart/admin/get-cart-orders/?cartOrderIds=" + cartOrderIds;

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;
      }

      this.cafeId = this.cartOrders[0].cafe.cafeId;
      this.cafeName = this.cartOrders[0].cafe.shopName;
      this.cafeAddress = this.cartOrders[0].cafe.address;
    },
    async getOrdersById(orderId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = BASE_URI + "/orders/admin/get-orders-by-id/?orderId=" + orderId;

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available anymore.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];
      this.orderStatus = result.data[0].orderStatus;
      this.customerName = result.data[0].customerInfo.fullName;
      this.discountPrice = result.data[0].discount;
      this.serviceFee = result.data[0].serviceFee;
      this.totalCartPrice = result.data[0].total;

      this.dateTimeOrdered = new Date(result.data[0].dateTimeOrdered);
      this.dateTimeOrdered = this.dateTimeOrdered.toLocaleString("en-US", {
        hour12: true,
      });

      this.dateTimeForPickup = new Date(this.dateTimeOrdered);
      this.dateTimeForPickup.setMinutes(
        this.dateTimeForPickup.getMinutes() + 15
      );
      this.dateTimeForPickup = this.dateTimeForPickup.toLocaleString("en-US", {
        hour12: true,
      });
    },
    async confirmOrder(orderId) {
      let updateOrderData = {
        orderId: orderId,
        status: "ready",
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let orderResult = await axios.put(
        BASE_URI + "/orders/update-order",
        updateOrderData,
        axiosConfig
      );

      if (orderResult.status === 200) {
        this.promptMessage = "Order has been confirmed.";
        await this.presentAlert(orderResult.status);
      } else {
        this.promptMessage = orderResult.data.error;
        await this.presentAlert(orderResult.status);
      }
    },
    async finishOrder(orderId, cafeId, userId) {
      let updateOrderData = {
        orderId: orderId,
        status: "collected",
      };

      let updateCartOrderData = {
        userId: userId,
        cafeId: cafeId,
        status: "collected",
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let orderResult = await axios.put(
        BASE_URI + "/orders/update-order",
        updateOrderData,
        axiosConfig
      );

      let cartOrderResult = await axios.put(
        BASE_URI + "/cart/update-cart-orders",
        updateCartOrderData,
        axiosConfig
      );

      if (orderResult.status === 200 && cartOrderResult.status === 200) {
        this.promptMessage = "Order has been finished.";
        await this.presentAlert(orderResult.status);
      } else {
        this.promptMessage = orderResult.data.error;
        await this.presentAlert(orderResult.status);
      }
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cartOrderIds = urlParams.get("cartOrderIds") || "";
    this.orderId = urlParams.get("orderId") || "";
    this.actionBtn = urlParams.get("action") || "";

    if (cartOrderIds && this.orderId) {
      await this.getCartOrders(cartOrderIds);
      await this.getOrdersById(this.orderId);
    } else {
      this.promptMessage = "No order has been selected.";
      this.presentAlert(400);
    }
  },
});
</script>
<style scoped>
.container-cart {
  padding-top: 10%;
  padding-bottom: 5%;
  padding-left: 8%;
}
.center {
  margin: auto;
}
.back-icon {
  height: 20px;
  width: 20px;
}
.h1-cart {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #1b1b1b;
  margin-left: 25%;
  top: 5px;
}
.heading-row-list {
  background-color: #f9f9f9;
  padding-left: 5%;
  border-bottom: 1px solid #f3f3f3;
}
.heading-category {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #1b1b1b;
  margin-top: 8%;
  margin-bottom: 15px;
}
.row-sub {
  padding-top: 0%;
  padding-left: 5%;
  padding-right: 0%;
}
.row-orderlist {
  padding-top: 0%;
  padding-left: 3%;
  padding-right: 0%;
}
.row-total {
  padding-top: 3%;
  padding-left: 2%;
  padding-right: 5%;
  margin-top: 3%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
}
.orderlist-div {
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-cart-quantity {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
}
.product-cart-name {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding: 0%;
}
.product-cart-addon {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  display: block;
  padding: 2%;
}
.product-total-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
  display: block;
  padding: 2%;
}
.product-cart-edit {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #ff8000;
  display: block;
  padding: 2%;
}
.product-cart-price {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: 16px;
}
.product-cart-price2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: -5px;
}
.product-subtotal {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: 16px;
}
.product-sublabel {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  height: 52px;
  text-transform: none;
  --box-shadow: none;
}
ion-button:active {
  --color: #ff8000;
}
.carttext-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
ion-select {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  --placeholder-color: #ffc34d;
  --placeholder-opacity: 1;
}
ion-select::part(icon) {
  color: #ffffff;
  opacity: 0;
}
ion-select-option {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #1b1b1b;
}
.select-fr {
  float: right;
}
.checkout-row {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 5%;
  padding-bottom: 5%;
}
.payment-row {
  border-bottom: 1px solid #f3f3f3;
}
.payment-col {
  padding-top: 5%;
  padding-left: 5%;
  padding-bottom: 5%;
}
.pickup-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #1b1b1b;
}
.payment-option {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
}
.banner-icon {
  vertical-align: middle;
  padding-right: 5px;
  width: 14px;
}
.trash-icon {
  width: 16px;
}
.payment-label {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
  margin-left: 5px;
}
.payment-label2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
  margin-right: 15px;
  float: right;
}
.payment-option {
  display: block;
}
.banner-icon2 {
  vertical-align: middle;
  padding-right: 5px;
  width: 80px;
  float: right;
  height: 35px;
}
</style>
