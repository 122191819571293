const cafeitem = [
  {
    id: 1,
    name: "Cappuccino",
    price: 5.0,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawc1.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", price: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 2,
    name: "Chai Latte",
    price: 5.0,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawchai.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 3,
    name: "Short Black",
    price: 4.5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawsb.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 4,
    name: "Long Black",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawhb.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 5,
    name: "Latte",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawlatte.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 6,
    name: "Piccollo",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawpicollo.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 8,
    name: "Macchiato",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawmacchiato.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 9,
    name: "Flat White",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawfw.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 10,
    name: "Mocha",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawmocha.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 11,
    name: "Matcha",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawmatcha.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 12,
    name: "Hot Chocolate ",
    price: 5,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawhc.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
  {
    id: 13,
    name: "Babyccino",
    price: 3,
    category: "Hot Beverages",
    imagepath: "",
    description: "this is a test",
    cafe: "LLaw Boutique Port Plaza",
    cafeimage: "assets/placeholders/llaw/llawbc.jpg",
    quantity: 0,
    addons: {
      name: ["Milk", "Size", "Sugar", "Strength", "Flavour"],
    },
    variation: {
      milk: [
        { name: "Full Cream", price: "0.10" },
        { name: "Almond", price: "0.50" },
        { name: "Skim", rice: "0.75" },
        { name: "Oat", price: "1.00" },
        { name: "Lactose Free", price: "2.00" },
        { name: "Soy", price: "3.00" },
      ],
      size: [
        { name: "Small", price: "0.10" },
        { name: "Medium", price: "1.00" },
        { name: "Large", price: "1.50" },
      ],
      sugar: [
        { name: "Raw Sugar", price: "0.50" },
        { name: "Normal Sugar", price: "0.10" },
        { name: "No Sugar", price: "0.20" },
        { name: "Half Sugar", price: "0.30" },
        { name: "Sweetner", price: "0.75" },
      ],
      strength: [
        { name: "Normal", price: "0.10" },
        { name: "Double Shot", price: "1.00" },
        { name: "Weak", price: "0.5" },
      ],
      flavour: [
        { name: "Hazelnut", price: "0.50" },
        { name: "Caramel", price: "0.75" },
        { name: "Vanilla", price: "1.00" },
      ],
    },
  },
];
export default cafeitem;
