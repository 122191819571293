<template>
  <ion-page>
    <!--content start -->
    <ion-content>
      <div class="container-cart">
        <ion-row>
          <ion-col size="3">
            <a @click="$router.go(-1)"
              ><ion-icon class="back-icon" src="assets/icon/arrow-left.svg"
            /></a>
          </ion-col>
          <ion-col size="7" class="h1-menu">
            <span class="h1-od">{{ dateTimeOrdered }}</span>
          </ion-col>
        </ion-row>
      </div>
      <ion-row class="od-row-list">
        <ion-col>
          <span class="h1-grey">
            Order #{{ orderId.replace(/-/gi, "").toUpperCase() }}
          </span>
          <span class="heading-category">{{ cafeName }}</span>
          <ion-icon src="assets/icon/tick-circle.svg" class="status-icon" />
          <span class="od-status">
            {{ orderStatus }}
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            />
            <span class="od-quantity">{{ totalCartQty }} items</span>
            <ion-icon
              src="assets/icon/dot-separate.svg"
              class="dotsep-icon"
              color="primary"
            />
            <ion-icon
              src="assets/icon/star.svg"
              class="banner-icon"
              color="primary"
            />
            <span class="rating-txt">{{ orderRatings.toFixed(2) }}</span>
          </span>
        </ion-col>
      </ion-row>
      <ion-button
        expand="block"
        color="primary"
        fill="outline"
        class="reorder-btn"
        @click="reorderItems"
        :disabled="isFetching"
        v-if="orderStatus == 'collected'"
      >
        Reorder
      </ion-button>
      <hr class="sep-div" />
      <div class="orderlist-div">
        <ion-row
          class="row-orderlist"
          v-for="orders in cartOrders"
          :key="orders.id"
        >
          <ion-col size="1">
            <span class="product-cart-name">{{ orders.quantity }} x</span>
          </ion-col>
          <ion-col>
            <span class="product-cart-name">
              {{ orders.product.productName }}
            </span>
            <div v-for="variations in orders.variations" :key="variations.id">
              <span class="product-cart-addon">
                {{ variations.name }}
              </span>
            </div>
          </ion-col>
          <ion-col>
            <span class="product-cart-price">
              $ {{ orders.pricePerOrder.toFixed(2) }}
            </span>
          </ion-col>
        </ion-row>
      </div>

      <div class="subtotal-div">
        <ion-row class="row-sub">
          <ion-col>
            <span class="od-label-left">
              SubTotal ({{ totalCartQty }} item/s)
            </span>
          </ion-col>
          <ion-col>
            <span class="od-label-right">
              $ {{ subTotalCartPrice.toFixed(2) }}
            </span>
          </ion-col>
        </ion-row>
        <ion-row class="row-sub">
          <ion-col>
            <span class="od-label-left">Discount</span>
          </ion-col>
          <ion-col>
            <span class="od-label-right">
              - $ {{ discountPrice.toFixed(2) }}
            </span>
          </ion-col>
        </ion-row>
        <ion-row class="row-sub">
          <ion-col>
            <span class="od-label-left">Service Fee</span>
          </ion-col>
          <ion-col>
            <span class="od-label-right"> $ {{ serviceFee.toFixed(2) }} </span>
          </ion-col>
        </ion-row>
        <ion-row class="row-sub">
          <ion-col>
            <span class="od-bold-left">Total</span>
          </ion-col>
          <ion-col>
            <span class="od-bold-right">
              $ {{ totalCartPrice.toFixed(2) }}
            </span>
          </ion-col>
        </ion-row>
      </div>

      <!-- content end -->
    </ion-content>
    <a :href="'/rate?cafeId=' + cafeId + '&orderId=' + orderId">
      <ion-button
        expand="block"
        color="primary"
        class="carttext-btn"
        style="margin-bottom: 5px"
        v-if="orderStatus == 'collected' && orderRatings == 0"
      >
        Rate Your Order
      </ion-button>
    </a>
    <router-link to="/support">
      <ion-button expand="block" color="primary" class="carttext-btn">
        Contact Support
      </ion-button>
    </router-link>
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
import {
  IonRow,
  IonCol,
  IonModal,
  IonButton,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonDatetimeButton,
  IonPage,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  alertController,
} from "@ionic/vue";

const BASE_URI =
  process.env.VUE_APP_BASE_URI !== undefined
    ? process.env.VUE_APP_BASE_URI
    : "https://api.dev.onecoffee.qusol.com.au";

export default defineComponent({
  name: "OrderDetailsPage",
  components: {
    IonRow,
    IonCol,
    IonModal,
    IonButton,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonDatetimeButton,
    IonPage,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
  },
  data() {
    return {
      cartOrders: [],
      totalCartQty: 0,
      subTotalCartPrice: 0,
      serviceFee: 0,
      totalCartPrice: 0,
      cafeId: "",
      cafeName: "",
      cafeAddress: "",
      orders: [],
      orderId: "",
      dateTimeOrdered: "",
      isFetching: false,
      promptMessage: "",
      cartOrderIds: "",
      orderStatus: "",
      orderRatings: 0,
      discountPrice: 0,
    };
  },
  methods: {
    async presentAlert(status) {
      const alert = await alertController.create({
        message: this.promptMessage,
      });

      await alert.present();
      await alert.onDidDismiss();
      if (status == 200 || status === 400) window.location.href = "/home";
      else return;
    },
    async getCartOrders(userId, cafeId, cartOrderIds) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/cart/get-cart-orders/?userId=" +
          userId +
          "&cafeId=" +
          cafeId +
          "&cartOrderIds=" +
          cartOrderIds
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.cartOrders = result && result.data.length ? await result.data : [];

      for (let i = 0; i < this.cartOrders.length; i++) {
        this.totalCartQty += this.cartOrders[i].quantity;
        this.subTotalCartPrice += this.cartOrders[i].pricePerOrder;
      }

      this.cafeId = this.cartOrders[0].cafe.cafeId;
      this.cafeName = this.cartOrders[0].cafe.shopName;
      this.cafeAddress = this.cartOrders[0].cafe.address;
    },
    async getOrders(userId, cafeId, cartOrderIds) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url = userId
        ? BASE_URI +
          "/orders/get-orders/?userId=" +
          userId +
          "&cafeId=" +
          cafeId +
          "&cartOrderIds=" +
          cartOrderIds
        : BASE_URI + "/cafe/get-coffee-shops";

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          this.promptMessage = "This order is not available anymore.";
          this.presentAlert(err.response.status);
          throw err.response.status;
        });

      this.orders = result && result.data.length ? await result.data : [];
      this.orderId = this.orders[0].uuid;
      this.dateTimeOrdered = this.orders[0].dateTimeOrdered;
      this.orderStatus = this.orders[0].status;
      this.discountPrice = this.orders[0].discount;
      this.serviceFee = result.data[0].serviceFee;
      this.totalCartPrice = result.data[0].total;

      this.getRatings(userId, cafeId, this.orderId);
    },
    async reorderItems() {
      let paramsData = {
        cartOrderIds: this.cartOrderIds,
      };

      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      this.isFetching = true;
      await axios
        .post(BASE_URI + "/cart/reorder-items", paramsData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            this.isFetching = false;
            this.promptMessage =
              "Item/s on this order has been successfully reordered.";
            this.presentAlert(res.status);
          }
        })
        .catch((err) => {
          this.isFetching = false;
          this.promptMessage =
            err && err.response && err.response.data
              ? err.response.data.error
              : "";
          this.presentAlert();
          return err;
        });
    },
    async getRatings(userId, cafeId, orderId) {
      let axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let url =
        BASE_URI +
        "/rate/get-ratings/?userId=" +
        userId +
        "&cafeId=" +
        cafeId +
        "&orderId=" +
        orderId;

      let result = await axios
        .get(url, axiosConfig)
        .then((res) => res.data)
        .catch((err) => {
          throw err.response.status;
        });

      this.orderRatings = result.data[0].ratings;
    },
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cafeId = urlParams.get("cafeId") || "";
    const cartOrderIds = urlParams.get("cartOrderIds") || "";
    this.cartOrderIds = cartOrderIds;
    const userId = localStorage.getItem("id") || null;

    if (cafeId == "") window.location.href = "/order-history";
    else {
      await this.getCartOrders(userId, cafeId, cartOrderIds);
      await this.getOrders(userId, cafeId, cartOrderIds);
    }
  },
});
</script>
<style scoped>
.container-cart {
  padding-top: 10%;
  padding-bottom: 5%;
  padding-left: 8%;
}
.back-icon {
  height: 20px;
  width: 20px;
}
.h1-od {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #1b1b1b;
}
.od-row-list {
  padding-left: 5%;
}
.heading-category {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #1b1b1b;
  margin-bottom: 15px;
  margin-top: 5px;
  display: block;
}
.h1-grey {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #828282;
  display: block;
}
.h2-grey {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  display: block;
  padding-bottom: 5px;
}
.od-status {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  color: #00bf60;
  text-transform: capitalize;
}
.od-quantity {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  color: #828282;
  text-transform: lowercase;
}
.sep-div {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 5px;
  padding-bottom: 5px;
}
.row-sub {
  padding-top: 0%;
  padding-left: 5%;
  padding-right: 0%;
}
.row-orderlist {
  padding-top: 0%;
  padding-left: 3%;
  padding-right: 0%;
}
.subtotal-div {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 5px;
}
.product-cart-name {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
  padding: 0%;
}
.product-cart-addon {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #828282;
  display: block;
  padding: 2%;
}
.product-cart-price {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: 16px;
}
.od-label-right {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: 16px;
}
.od-label-left {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
}
.od-bold-right {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  position: absolute;
  right: 16px;
}
.od-bold-left {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  display: block;
}
ion-button {
  --background: #ffffff;
  --background-hover: #dce8ff;
  --background-activated: #dce8ff;
  --background-focused: #dce8ff;
  --color: #1b1b1b;
  --border-radius: 4px;
  --border-color: #f3f3f3;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dce8ff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 52px;
  text-transform: none;
}
ion-button:active {
  --color: #ff8000;
}
.carttext-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.reorder-btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 0px 10px 0px 10px;
}
.status-icon {
  vertical-align: middle;
  padding-right: 5px;
}
.dotsep-icon {
  vertical-align: middle;
  width: 3px;
  height: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.rating-txt {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 132.6%;
  letter-spacing: 0.02em;
  color: #1b1b1b;
  padding-left: 10px;
}
</style>
